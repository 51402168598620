import React, { useEffect, useState, useRef, useMemo, useCallback, useImperativeHandle, forwardRef } from 'react';
import debounce from 'lodash.debounce';
import { Loader } from '@progress/kendo-react-indicators';
import {
  AuthorityLevel,
  AuthorityType,
  LayerType,
  ViewLayer,
  PointcloudVisualization,
  PotreePointcloudAttribute,
  PCAttributeType,
  PCAttributeVisualization,
  SapFlowViewConfig,
  Unit,
  Appearance3D,
} from '../../../types';
import { Vector3, compareVectors, isZeroVector } from '../../../types/Vector';
import { useConsumeViewerState } from '../../../context/viewer';
import { useGeolocation, useAnimationFrame, useDelayedExecution } from '../../../hooks/common';
import { isIOS } from '../../../common/device';
import { updateLayerParam, updateViewConfigParam } from '../../../common/viewerConfigHelper';
import {
  SUPPORTED_ATTRIBUTES,
  IGNORED_ATTRIBUTES,
  ELEVATION,
  CLASSIFICATION,
  HEIGHT,
  DEFAULT_ATTRIBUTE_VALUES,
  SAMPLECLASSIFICATION,
  RGBA,
  INTENSITY,
} from './SupportedAttributes';
import AttributeConfig from './AttributeConfigWindow';
import ViewerToolbar from './ViewerToolbar';
import { useUser } from '../../../hooks/authentication';
import { getProjUnits, unitToPotreeLengthUnits } from '../../../common/proj4Helper';

// To avoid transpiler error on window.Potree  https://stackoverflow.com/questions/56457935/typescript-error-property-x-does-not-exist-on-type-window
declare const window: any;

const Potree = window.Potree;
//const THREE = window.THREE;

let potreeViewer: any = null;

interface Props {
  show?: boolean;
  handleCameraMoved: (threeScene: any) => void;
  onLayerUpdated?: (layer: ViewLayer, persistent?: boolean) => void;
  onViewConfigParamsUpdate?: (viewConfig: SapFlowViewConfig, persistent: boolean, immediate: boolean) => void;
  onSaveView?: () => void;
}

type Ref = {
  zoomToLayer: (layer: any) => void;
  changeNodeVisibility: (layer: any, visible: boolean) => void;
} | null;

const PotreeViewer = forwardRef<Ref, Props>((props, ref) => {
  const {
    dispatch,
    viewConfig,
    viewConfigParams,
    layers,
    selectedLayer,
    potreeProjection,
    potreeInitialized,
    cameraPosition3D,
    cameraTarget3D,
    appearance3D,
    units,
  } = useConsumeViewerState();
  const { userHasAuthority } = useUser();
  const viewerStateRef = React.useRef(null);
  viewerStateRef.current = viewConfig;
  const [potreeNodes, setPotreeNodes] = useState<any>({});
  const [currentPointcloudAttributes, setCurrentPointcloudAttributes] = useState<any[]>([]);
  const [selectedPointcloudAttribute, setSelectedPointcloudAttribute] = useState<any>(null);
  const [attributeDialogVisible, setAttributeDialogVisible] = useState<boolean>(true);
  const [viewConfigUpdatingThrottle, setViewConfigUpdatingThrottle] = useState<boolean>(false);
  const [viewer, setViewer] = useState<any>(null);
  //const [cameraPosition, setCameraPosition] = useState<Vector3>({ x: 0, y: 0, z: 0 });
  //const [cameraTarget, setCameraTarget] = useState<any>({ x: 0, y: 0, z: 0 });
  const potreeContainerDiv = useRef(null);
  const requestRef = useRef(null);
  const { geolocationAvailable, getProjectedLocation } = useGeolocation();
  const [statePotreeViewer, setStatePotreeViewer] = useState<any>(null);
  const delayedViewConfigUpdater = useDelayedExecution(3000); // 3 seconds delay

  useEffect(() => {
    // initialize Potree viewer
    const viewerElem = potreeContainerDiv.current;
    if (statePotreeViewer === null) {
      potreeViewer = new Potree.Viewer(viewerElem, {
        useDefaultRenderLoop: false,
      });
    } else {
      potreeViewer = statePotreeViewer;
      potreeViewer.scene.clear();
    }

    potreeViewer.setEDLEnabled(true);
    potreeViewer.setFOV(65);
    potreeViewer.setPointBudget(10 * 1000 * 1000);
    potreeViewer.setBackground(/*displayCesium || displayLeaflet ? */ null);
    if (!isIOS) {
      potreeViewer.useHQ = true;
    }
    potreeViewer.setControls(potreeViewer.orbitControls);

    potreeViewer.loadGUI(() => {
      potreeViewer.setLanguage('en');
    });
    setViewer(potreeViewer);
  }, []);

  const onClassificationUpdate = (
    layer: ViewLayer,
    attributeName: string,
    id: number,
    name: string,
    visible: boolean,
    color: number[]
  ) => {
    const visualization: PointcloudVisualization = layer.paramsMap['visualization'];
    let classificationAttribute = visualization.attributeValues[attributeName];
    if (!classificationAttribute) {
      classificationAttribute = {
        attributeType: PCAttributeType.CLASSIFICATION,
        classificationSchemas: {},
      };
    } else if (!classificationAttribute.classificationSchemas) {
      classificationAttribute.classificationSchemas = {};
    }
    classificationAttribute.classificationSchemas[id] = {
      visible: visible,
      index: id,
      name: name,
      color: color,
    };
    visualization.attributeValues[attributeName] = classificationAttribute;
    updateLayerParam(layer, 'visualization', visualization);
    props.onLayerUpdated(layer, false);
    updatePotreeClassification(attributeName, id, name, visible, color);
  };

  const updatePotreeClassification = (
    attributeName: string,
    id: number | string,
    name: string,
    visible: boolean,
    color: number[]
  ) => {
    const potreeAttributeName = attributeName === CLASSIFICATION ? 'DEFAULT' : attributeName;
    if (!potreeViewer.classifications[potreeAttributeName]) {
      potreeViewer.classifications[potreeAttributeName] = {};
    }
    if (!potreeViewer.classifications[potreeAttributeName][id]) {
      potreeViewer.classifications[potreeAttributeName][id] = {};
    }
    potreeViewer.classifications[potreeAttributeName][id].visible = visible;
    potreeViewer.classifications[potreeAttributeName][id].name = name;
    potreeViewer.classifications[potreeAttributeName][id].color = color;
  };

  useEffect(() => {
    // TODO: This is a patch to deep copy the viewconfig so that the object doesn't get modified with THREEjs Object3D objects. Making the stringification impossible later on.
    // Caused problems in the handleRenameLayer function
    if (!viewConfig) return;

    potreeViewer.setDescription(null);
    potreeViewer.setFOV(appearance3D.fov);
    potreeViewer.setPointBudget(appearance3D.pointBudget);
    if (!isIOS) {
      potreeViewer.useHQ = appearance3D.highQuality;
    }

    setTimeout(() => {
      if (cameraPosition3D)
        potreeViewer.scene.view.position.set(cameraPosition3D.x, cameraPosition3D.y, cameraPosition3D.z);
      if (cameraTarget3D) potreeViewer.scene.view.lookAt(cameraTarget3D.x, cameraTarget3D.y, cameraTarget3D.z);

      //if(props.handleCameraMoved)props.handleCameraMoved(potreeViewer.scene);
    }, 200);

    const loadPotreeLayers = async (layersConfig: any) => {
      const loader = new Potree.ShapefileLoader();
      const newPotreeNodes: any = {};
      let i = 0;
      for (i = 0; i < layersConfig.length; i++) {
        const layer: ViewLayer = layersConfig[i];
        if (potreeNodes[layer.id] !== null && potreeNodes[layer.id] !== undefined) {
          newPotreeNodes[layer.id] = potreeNodes[layer.id];
          newPotreeNodes[layer.id].material.size = appearance3D.pointSize; //layer.materialSize;
          newPotreeNodes[layer.id].material.pointSizeType = appearance3D.pointSizing;
          newPotreeNodes[layer.id].material.shape = appearance3D.pointShape;
          continue;
        }
        if (layer.layerType === LayerType.OctreeBin || layer.layerType === LayerType.UNKNOWN.valueOf()) {
          let e: any = null;
          try {
            e = await Potree.loadPointCloud(layer.uri);
          } catch (e: any) {
            console.log('ERROR: ', e);
            return;
          }
          const pointcloud = e.pointcloud;
          const material = pointcloud.material;

          material.activeAttributeName = '';
          material.size = appearance3D.pointSize;
          material.pointSizeType = appearance3D.pointSizing;
          material.shape = appearance3D.pointShape;

          material.gradient = Potree.Gradients.RAINBOW;

          const visualization: PointcloudVisualization = extractVisConfig(selectedLayer, pointcloud);
          Object.keys(visualization.attributeValues).forEach((attrName) => {
            const attribute: PCAttributeVisualization = visualization.attributeValues[attrName];
            if (attrName === RGBA) {
              material.rgbGamma = attribute.gamma;
              material.rgbBrightness = attribute.brightness;
              material.rgbContrast = attribute.contrast;
            } else if (attrName === INTENSITY) {
              material.intensityRange = [attribute.min, attribute.max];
            } else if (attrName === ELEVATION) {
              material.heightMin = attribute.min;
              material.heightMax = attribute.max;
            } else if (attrName === CLASSIFICATION) {
              Object.keys(attribute.classificationSchemas).forEach((classSchemaId) => {
                updatePotreeClassification(
                  attrName,
                  classSchemaId,
                  attribute.classificationSchemas[classSchemaId].name,
                  attribute.classificationSchemas[classSchemaId].visible,
                  attribute.classificationSchemas[classSchemaId].color
                );
              });
            } else {
              if (visualization.attributeValues[attrName].attributeType === PCAttributeType.RANGE) {
                const rangeAttribute: PCAttributeVisualization = visualization.attributeValues[attrName];
                material.setRange(attrName, [rangeAttribute.min, rangeAttribute.max]);
              } else if (visualization.attributeValues[attrName].attributeType === PCAttributeType.CLASSIFICATION) {
                Object.keys(attribute.classificationSchemas).forEach((classSchemaId) => {
                  updatePotreeClassification(
                    attrName,
                    classSchemaId,
                    attribute.classificationSchemas[classSchemaId].name,
                    attribute.classificationSchemas[classSchemaId].visible,
                    attribute.classificationSchemas[classSchemaId].color
                  );
                });
              }
            }
          });

          pointcloud.visible = layer.active && props.show;
          newPotreeNodes[layer.id] = pointcloud;

          potreeViewer.scene.addPointCloud(pointcloud);

          if (i === 0) {
            potreeViewer.fitToScreen();
            potreeViewer.setBackView();
          }
        } else if (layer.layerType === LayerType.ShapeFile) {
          try {
            // Start by checking whether we have a projection saved:
            let shapefileProj = null;
            if (layer.paramsMap && layer.paramsMap.projection) {
              shapefileProj = layer.paramsMap.projection;
            } else {
              //shapefileProj = await loader.loadShapefilePrjData(layer.uri);
              if (shapefileProj) {
                // Found a projection file for the shapefile. Save it in ViewLayer
                updateLayerParam(layer, 'projection', shapefileProj);
                props.onLayerUpdated(layer);
              }
            }
            const shpPoints = await loader.load(layer.uri, layer.paramsMap.projection, potreeViewer.getProjection());
            shpPoints.node.visible = layer.active;

            newPotreeNodes[layer.id] = shpPoints.node;

            potreeViewer.scene.addShapefile(shpPoints.node);
          } catch (e) {
            console.error(e);
          }
        }
      }

      return newPotreeNodes;
    };

    loadPotreeLayers(viewConfig.layers).then((newPotreeNodes: any) => {
      let proj = potreeViewer.getProjection();
      if (!proj) {
        proj =
          '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +units=ft +no_defs';
      }
      const projUnits = getProjUnits(proj);
      dispatch({ type: 'POTREE_PROJ', payload: { proj, projUnits } });
      potreeViewer.lengthUnit = unitToPotreeLengthUnits(projUnits);

      if (geolocationAvailable()) {
        getProjectedLocation(proj).then((coords: any) => {
          potreeViewer.markerTool.createMarker({
            position: [...coords, 100],
            description: 'You are here',
          });
        });
      }

      setStatePotreeViewer(potreeViewer);

      dispatch({ type: 'POTREE_INIT' });
      setPotreeNodes(newPotreeNodes);
    });

    requestRef.current = requestAnimationFrame(loop);
    return () => cancelAnimationFrame(requestRef.current);
  }, [viewConfig, appearance3D]);

  // INITIAL POSITIONING
  useEffect(() => {
    if (potreeNodes && potreeInitialized && selectedLayer) {
      //const camPos = viewConfig.cameraPosition;
      //const camTarget = viewConfig.cameraTarget;
      setTimeout(() => {
        if (isZeroVector(cameraPosition3D) || isZeroVector(cameraTarget3D)) {
          zoomToLayer(selectedLayer);
        } else {
          potreeViewer.scene.view.position.set(cameraPosition3D.x, cameraPosition3D.y, cameraPosition3D.z);
          potreeViewer.scene.view.lookAt(cameraTarget3D.x, cameraTarget3D.y, cameraTarget3D.z);
        }
      }, 500);
    }
  }, [potreeNodes, potreeInitialized]);

  useEffect(() => {
    if (potreeInitialized && units) {
      potreeViewer.lengthUnitDisplay = unitToPotreeLengthUnits(units);
    }
  }, [potreeInitialized, units]);

  useEffect(() => {
    if (layers && potreeNodes) {
      layers.forEach((layer) => {
        if (!potreeNodes[layer.id]) {
          // Add new layer ?
        } else {
          // Make sure layer and potree node are synched
          potreeNodes[layer.id].visible = layer.active;
          // TODO: Get the attribute change code here
        }
      });
      potreeViewer.lengthUnitDisplay = unitToPotreeLengthUnits(units);
    }
  }, [layers, potreeNodes]);

  const attributeChange = (event: any) => {
    const attributeValue = event.target.value;
    const currentPointcloud = potreeNodes[selectedLayer.id];

    if (selectedLayer.layerType === LayerType.OctreeBin) {
      currentPointcloud.material.activeAttributeName = attributeValue.id;
      setAttributeDialogVisible(true);

      setSelectedPointcloudAttribute(attributeValue);
    } else {
      setAttributeDialogVisible(true);

      setSelectedPointcloudAttribute(attributeValue);
    }
    const selectedLayerClone = JSON.parse(JSON.stringify(selectedLayer));
    let visualization: PointcloudVisualization = selectedLayerClone.paramsMap['visualization'];
    if (!visualization) {
      visualization = extractVisConfig(selectedLayerClone, potreeNodes[selectedLayerClone.id]);
    }
    visualization.attribute = attributeValue.id;
    updateLayerParam(selectedLayerClone, 'visualization', visualization);
    props.onLayerUpdated(selectedLayerClone, false);
  };

  const extractVisConfig = (layer: ViewLayer, potreeNode: any): PointcloudVisualization => {
    let visualization: PointcloudVisualization = selectedLayer.paramsMap['visualization'];
    if (!visualization) {
      visualization = {
        attribute: null,
        attributeValues: {},
      };
    }
    if (!visualization.attributeValues) {
      visualization.attributeValues = {};
    }
    if (!visualization.attributeValues.rgba) {
      visualization.attributeValues.rgba = {
        attributeType: PCAttributeType.COLORED,
        gamma: 2, // Range [0, 4]
        brightness: 0, // Range [-1, 1]
        contrast: 0, // Range [-1, 1]
      };
    }
    if (!potreeNode) {
      return visualization;
    }
    const pointcloudAttributes: PotreePointcloudAttribute[] = potreeNode.getAttributes().attributes;
    pointcloudAttributes.forEach((potreeAttr) => {
      if (!IGNORED_ATTRIBUTES.includes(potreeAttr.name)) {
        if (potreeAttr.distinctValues && potreeAttr.distinctValues.length > 0) {
          // Classification attribute
        } else {
          // Range attribute
          if (!visualization.attributeValues[potreeAttr.name]) {
            visualization.attributeValues[potreeAttr.name] = {
              attributeType: PCAttributeType.RANGE,
              min: potreeAttr.range[0],
              max: potreeAttr.range[1],
            };
          }
        }
      }
    });
    return visualization;
  };

  const formatAttributeName = (attributeName: string) => {
    attributeName = attributeName[0].toLowerCase() + attributeName.slice(1);
    // attrName = attrName.replace(/(?<==)([A-Z])/g, ' $1');
    attributeName = attributeName.replace(/([^ ][a-z]|[^a-z]|^)\.|\.(?=[^ ])/g, '$1 ');

    attributeName = attributeName.replace(/\b\w/g, function (c: any) {
      return c.toUpperCase();
    });
    // Add a regular expression to insert space before capital letters not at the start
    attributeName = attributeName.replace(/([a-z])([A-Z])/g, '$1 $2');
    return attributeName;
  };

  useEffect(() => {
    if (!selectedLayer || !potreeNodes[selectedLayer.id]) {
      setCurrentPointcloudAttributes([]);
      setSelectedPointcloudAttribute(null);
      return;
    }
    if (selectedLayer.layerType === LayerType.OctreeBin) {
      const visualization = extractVisConfig(selectedLayer, potreeNodes[selectedLayer.id]);

      const attributes = [...potreeNodes[selectedLayer.id].pcoGeometry.pointAttributes.attributes];
      attributes.push({ name: ELEVATION });
      const newAttributesOptions: any[] = [];

      const currentLayerAttribute: any = potreeNodes[selectedLayer.id].material.activeAttributeName;
      let defaultSelected: any = null;
      const selectDefaultAttribute = !currentLayerAttribute || currentLayerAttribute === '';
      attributes.forEach((attribute: any) => {
        if (!IGNORED_ATTRIBUTES.includes(attribute.name)) {
          const attrName = formatAttributeName(attribute.name);
          newAttributesOptions.push({ text: attrName, id: attribute.name });

          if (visualization.attribute && visualization.attribute === attribute.name) {
            defaultSelected = { text: attrName, id: attribute.name };
          }

          if (!defaultSelected) {
            if (currentLayerAttribute === attribute.name) {
              defaultSelected = { text: attrName, id: attribute.name };
            } else if (selectDefaultAttribute && attribute.name === 'TreeID') {
              defaultSelected = { text: attrName, id: attribute.name };
            } else if (selectDefaultAttribute && attribute.name === 'Rgba') {
              defaultSelected = { text: attrName, id: attribute.name };
            } else if (selectDefaultAttribute && attribute.name === ELEVATION) {
              defaultSelected = { text: attrName, id: attribute.name };
            }
          }
        }
      });


      if (defaultSelected) {
        visualization.attribute = defaultSelected.id;
        potreeNodes[selectedLayer.id].material.activeAttributeName = defaultSelected.id;
      }

      if (!selectedLayer.paramsMap['visualization']) {
        updateLayerParam(selectedLayer, 'visualization', visualization);
        props.onLayerUpdated(selectedLayer, true);
      }

      setCurrentPointcloudAttributes(newAttributesOptions);
      setSelectedPointcloudAttribute(defaultSelected);
    } else if (selectedLayer.layerType === LayerType.ShapeFile) {
      setCurrentPointcloudAttributes([{ text: HEIGHT, id: HEIGHT }]);
      setSelectedPointcloudAttribute({ text: HEIGHT, id: HEIGHT });
    } else {
      setCurrentPointcloudAttributes([]);
      setSelectedPointcloudAttribute(null);
    }
  }, [selectedLayer, potreeNodes]);

  useImperativeHandle(ref, () => ({
    zoomToLayer,
    changeNodeVisibility,
  }));

  const zoomToLayer = (layer: any) => {
    if (potreeNodes[layer.id]) {
      potreeViewer.zoomTo(potreeNodes[layer.id], 1, 1);
    }
  };

  const changeNodeVisibility = (layer: any, visible: boolean) => {
    if (potreeNodes[layer.id]) {
      potreeNodes[layer.id].visible = visible && props.show;
    }
  };
  useEffect(() => {
    layers.forEach((layer: ViewLayer) => {
      if (potreeNodes[layer.id]) {
        potreeNodes[layer.id].visible = props.show && layer.active;
      }
    });
  }, [props.show]);

  const assignCameraPosAnTarget = (cameraPosition: Vector3, cameraTarget: Vector3) => {
    const currentCameraTarget = potreeViewer.scene.view.direction
      .clone()
      .multiplyScalar(potreeViewer.scene.view.radius)
      .add(potreeViewer.scene.view.position);
    if (
      compareVectors(cameraPosition, potreeViewer.scene.view.position) ||
      compareVectors(cameraTarget, currentCameraTarget)
    ) {
      dispatch({
        type: 'POTREE_CAMERA_MOVED',
        payload: { position: potreeViewer.scene.view.position.clone(), target: currentCameraTarget },
      });
    }
  };

  const throttledCameraAssignment = useCallback(
    debounce((cameraPosition, cameraTarget) => {
      assignCameraPosAnTarget(cameraPosition, cameraTarget);
    }, 200),
    [assignCameraPosAnTarget]
  );

  useAnimationFrame(
    (deltaTime, timestamp) => {
      loop(timestamp);
    },
    [cameraPosition3D, cameraTarget3D]
  );
  let tempCameraPosition = { x: 0, y: 0, z: 0 };
  const loop = (timestamp: any) => {
    if (!potreeViewer || !props.show || !potreeInitialized) return;

    potreeViewer.update(potreeViewer.clock.getDelta(), timestamp);

    potreeViewer.render();

    const cameraMoved = compareVectors(tempCameraPosition, potreeViewer.scene.view.position);

    if (cameraMoved && !isZeroVector(potreeViewer.scene.view.position)) {
      throttledCameraAssignment(cameraPosition3D, cameraTarget3D);
      if (props.handleCameraMoved) props.handleCameraMoved(potreeViewer.scene);
      tempCameraPosition = { ...potreeViewer.scene.view.position };
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
        opacity: props.show ? 1 : 0,
        pointerEvents: props.show ? 'auto' : 'none',
        zIndex: props.show ? 2 : -1,
      }}
    >
      <ViewerToolbar
        viewer={viewer}
        projectName={viewConfig?.pageName}
        currentPointcloudAttributes={currentPointcloudAttributes}
        currentAttributeValue={selectedPointcloudAttribute}
        onAttributeChange={attributeChange}
        onSaveView={props.onSaveView}
      />
      <AttributeConfig
        visible={
          attributeDialogVisible &&
          selectedPointcloudAttribute?.id !== null &&
          selectedPointcloudAttribute?.id !== undefined
        }
        attribute={selectedPointcloudAttribute?.id}
        pointcloud={selectedLayer ? potreeNodes[selectedLayer.id] : null}
        onClose={() => {
          setAttributeDialogVisible(false);
        }}
        viewer={viewer}
        layer={selectedLayer}
        onLayerUpdate={props.onLayerUpdated}
        onClassificationUpdate={onClassificationUpdate}
      />
      <div className="position-relative w-100 h-100">
        <div
          className="position-absolute top-0 end-0 zindex-popover p-3 mt-1"
          style={{
            zIndex: 1,
            display: viewConfigUpdatingThrottle ? 'block' : 'none',
          }}
        >
          <Loader size="small" type={'converging-spinner'} themeColor={'secondary'} />
        </div>
        <div id="potree_render_area" ref={potreeContainerDiv}></div>
      </div>

      <div
        className=""
        style={{
          position: 'absolute',
          zIndex: '1000',
          display: 'flex',
          flexDirection: 'column',
          bottom: 0,
          left: '50%',
        }}
      ></div>
    </div>
  );
});

PotreeViewer.displayName = 'PotreeViewer';

export default PotreeViewer;
