import jwtDecode, { JwtPayload } from 'jwt-decode';
import { User, Organization, UserRole, SharedFileNode, SharedTransaction } from '../../types';
import { useAppContext } from '../../context/app';
import useOrganization from './useOrganization';
import { AuthorityType, AuthorityLevel, Authority } from '../../types/UserManagement/Authority';

const useUser = () => {
  const { user, selectedOrganization, accessToken, viewAccessToken, tokenType, sharedFilenode, sharedTransaction } =
    useAppContext();

  const getUser = (): User => user;
  const getAccessToken = (): string => accessToken;
  const getViewAccessToken = (): string => viewAccessToken;
  const getSharedFileNode = (): SharedFileNode => sharedFilenode;
  const getSharedTransaction = (): SharedTransaction => sharedTransaction;
  const getTokenType = (): string => tokenType;
  const getCurrentOrganization = (): Organization => selectedOrganization;

  const isAuthenticated = (): boolean => {
    if (accessToken) {
      const { exp } = jwtDecode<JwtPayload>(accessToken);
      return new Date(exp * 1000) > new Date();
    }
    return false;
  };

  const isViewAuthenticated = (): boolean => {
    if (viewAccessToken) {
      const { exp } = jwtDecode<JwtPayload>(viewAccessToken);
      return new Date(exp * 1000) > new Date();
    }
    return false;
  };

  const organizationSelected = (): boolean => {
    return selectedOrganization !== null;
  };

  const userHasAuthority = (authorityType: AuthorityType, authorityLevel: AuthorityLevel): boolean => {
    if (!selectedOrganization || !selectedOrganization.userRole || !selectedOrganization.userRole.authoritiesMap)
      return false;

    const authority: Authority = selectedOrganization.userRole.authoritiesMap[authorityType];
    if (!authority) return false;

    switch (authorityLevel) {
      case AuthorityLevel.CREATE: {
        return authority.canCreate;
      }
      case AuthorityLevel.READ: {
        return authority.canRead;
      }
      case AuthorityLevel.UPDATE: {
        return authority.canUpdate;
      }
      case AuthorityLevel.DELETE: {
        return authority.canDelete;
      }
      case AuthorityLevel.EXECUTE: {
        return authority.canExecute;
      }
      case AuthorityLevel.SHARE: {
        return authority.canShare;
      }
    }
  };

  const userHasRole = (role: UserRole) => {
    if (!selectedOrganization || !selectedOrganization.userRole || !selectedOrganization.userRole.role) return false;

    return selectedOrganization.userRole.role === role;
  };

  return {
    getUser,
    getAccessToken,
    getTokenType,
    getViewAccessToken,
    getSharedFileNode,
    getSharedTransaction,
    isAuthenticated,
    isViewAuthenticated,
    organizationSelected,
    getCurrentOrganization,
    userHasAuthority,
    userHasRole,
  };
};

export default useUser;
