import { useMutation } from 'react-query';
import {
  LOCAL_STORAGE_USER,
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_TOKEN_TYPE,
  LOCAL_STORAGE_CURRENT_ORG,
} from '../../common/constants';
import { User, SigninRequest, SigninResponse } from '../../types';
import { useAppContext } from '../../context/app';
import { useAxiosInstance } from '../common';

const endpoint = '/authentication/signin';

const useSignin = (onSuccess: VoidFunction, onError: any) => {
  const { dispatch } = useAppContext();
  const axios = useAxiosInstance();

  return useMutation(
    async (newUser: SigninRequest) => {
      const response = await axios.post<SigninResponse>(endpoint, newUser);
      if (response) {
        if (response.data && response.data.accessToken) {
          const user: User = {
            id: response.data.id,
            email: response.data.email,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            organizations: response.data.organizations,
          };
          localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
          localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, response.data.accessToken);
          localStorage.setItem(LOCAL_STORAGE_TOKEN_TYPE, response.data.tokenType);
          let selectedOrganization = null;
          if (!response.data.organizations || response.data.organizations?.length === 0) {
            selectedOrganization = null;
          } else if (response.data.organizations && response.data.organizations?.length === 1) {
            selectedOrganization = response.data.organizations[0];
          }

          dispatch({
            type: 'LOGIN',
            payload: { user: user, accessToken: response.data.accessToken, tokenType: response.data.tokenType },
          });

          if (selectedOrganization) {
            // Build the userAuthority mapping
            dispatch({ type: 'SELECT_ORGANIZATION', payload: { organization: selectedOrganization } });
          }
        }
        onSuccess();
      }
    },
    { onError }
  );
};

export default useSignin;
