import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    useStripeProducts,
    useStripePrice,
} from '../../../hooks/billing';
import {
  Organization,
  StripeProduct,
} from '../../../types';
import { useAppContext } from '../../../context/app';
import { useUser } from '../../../hooks/authentication';
import { Typography } from '@progress/kendo-react-common';
import { Loader } from '@progress/kendo-react-indicators';
import { centsStrToDollars } from '../../../common/stringHelper';
import SubscriptionPlan from './SubscriptionPlan';

interface Props {
  organization: Organization;
}

enum BillingState {
    Loading,
    Unsubscribed,
    Promo,
    OnExpiringTrial,
    OnRenewingTrial,
    OnRenewingSubscription,
    OnExpiringSubscription,
    FailedPayment,
}

const SubscriptionSection: React.FC<Props> = (props) => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const { getCurrentOrganization, userHasAuthority, userHasRole } = useUser();
  const stripeProductQuery = useStripeProducts();
  const [billingState, setBillingState] = useState<BillingState>(BillingState.Loading);

  const orgBilling = getCurrentOrganization()?.orgBillingDetails;

  useEffect(() => {
    if(!orgBilling) {
        setBillingState(BillingState.Unsubscribed);
    }
    else{
        if(orgBilling.freeTrialActive) {
            if(orgBilling.subscriptionWillRenew) {
                setBillingState(BillingState.OnRenewingTrial);
            }
            else {
                setBillingState(BillingState.OnExpiringTrial);
            }
        }
        else if(orgBilling.subscriptionActive){
            if(orgBilling.subscriptionWillRenew) {
                setBillingState(BillingState.OnRenewingSubscription);
            }
            else {
                setBillingState(BillingState.OnExpiringSubscription);
            }
        }
        else if(orgBilling.failedPayment){
            setBillingState(BillingState.FailedPayment);
        }
        else {
            setBillingState(BillingState.Unsubscribed);
        }
    }
  }, []);

  const renderPlans = () => {
    if(billingState !== BillingState.Unsubscribed && billingState !== BillingState.OnExpiringTrial && billingState !== BillingState.OnExpiringSubscription)
        return null;
    else if(stripeProductQuery.isLoading)
        return <Loader></Loader>
    else if(!stripeProductQuery.isSuccess)
        return <span>Failed to retrieve Plans.</span>
    return (
        <div style={{width: '100%', display: 'flex'}}>
            {stripeProductQuery.data.map((productWithPrice, index)=><SubscriptionPlan key={index} product={productWithPrice} index={index}/>)}
        </div>);
  }

  return (
    <div className="py-4">
        <Typography.h5>Billing</Typography.h5>
        {billingState === BillingState.Loading && <Loader/>}
        {billingState === BillingState.Promo && <Typography.h6>{`This organization doesn't require any subscription.`}</Typography.h6>}
        {billingState === BillingState.Unsubscribed && <Typography.h6>{`This organization isn't subscribed and will have limited access to geoSAP.`}</Typography.h6>}
        {billingState === BillingState.OnRenewingTrial && <Typography.h6>{`Free trial ends on ${orgBilling.freeTrialEnd}. Subscription will then start.`}</Typography.h6>}
        {billingState === BillingState.OnExpiringTrial && <Typography.h6>{`Free trial ends on ${orgBilling.freeTrialEnd}. Make sure to subscribe to avoid losing access.`}</Typography.h6>}
        {billingState === BillingState.OnRenewingSubscription && <Typography.h6>{`Subscription will renew on ${orgBilling.subscriptionExpiration}.`}</Typography.h6>}
        {billingState === BillingState.OnExpiringSubscription && <Typography.h6>{`Subscription will end on ${orgBilling.subscriptionExpiration}.`}</Typography.h6>}
        {billingState === BillingState.FailedPayment && <Typography.h6>{`Subscription renewal failed.`}</Typography.h6>}

        {renderPlans()}
    </div>
  );
};

export default SubscriptionSection;
