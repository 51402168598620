/* eslint-disable @typescript-eslint/no-explicit-any */
/* Kendo ComboBox uses any so this component is gonna use it for now as well. */
import React, { useRef, useEffect, ComponentType } from 'react';
import styled from 'styled-components';
import {
  DropDownListHandle,
  DropDownListChangeEvent,
  DropDownListBlurEvent,
  DropDownList,
  DropDownListProps,
} from '@progress/kendo-react-dropdowns';
import FieldBase, { FieldBaseProps } from './FieldBase';

interface Props extends FieldBaseProps {
  placeholder?: string;
  data?: any[];
  dataItemKey: string;
  textField: string;
  loading?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  value: any;
  onChange?: (e: DropDownListChangeEvent) => void;
  onBlur?: (e: DropDownListBlurEvent) => void;
  className?: string;
}

interface StyleProps {
  placeholder?: string;
}

const StyledDropDownList = styled<ComponentType<DropDownListProps & StyleProps>>(DropDownList)`
  border: 2px solid var(--geosap-input-border-color);
  background: var(--geosap-light);
  color: var(--geosap-primary-color);
  &:hover {
    border: 2px solid var(--geosap-input-border-color);
    background: var(--geosap-light);
  }
  &:focus {
    box-shadow: var(--geosap-input-focus-shadow);
  }
  .k-input-inner {
    padding: 0;
  }
  .k-button {
    padding: 0;
    background: transparent;
    border: none;
  }
  .k-input-value-text:empty::after {
    content: '${(props) => props.placeholder}';
  }
`;

const Select: React.FC<Props> = (props) => {
  const {
    placeholder = '',
    data,
    dataItemKey,
    textField,
    loading,
    disabled,
    autoFocus,
    value,
    error,
    onChange,
    onBlur,
  } = props;
  const ref = useRef<DropDownListHandle>();

  useEffect(() => {
    if (!loading && autoFocus) {
      ref.current.focus();
    }
  }, [loading]);

  return (
    <FieldBase {...props}>
      <StyledDropDownList
        data={data}
        dataItemKey={dataItemKey}
        textField={textField}
        className={props.className}
        placeholder={loading ? 'Loading...' : placeholder}
        disabled={loading || disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        valid={!error}
      />
    </FieldBase>
  );
};

export default Select;
