import React, { useEffect, Fragment } from 'react';
import { Switch } from '@progress/kendo-react-inputs';
import { useForm, Controller, FieldError, UseFormProps } from 'react-hook-form';
import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { ExpansionPanelContent } from '@progress/kendo-react-layout';
import { Checkbox } from '@progress/kendo-react-inputs';
import * as yup from 'yup';
import {
  SapFlowConfig,
  SapFlowProcessNodeParamDetails,
  SapFlowConfigParamDetails,
  SapFlowConfigParamType,
  SapFlowConfigProcessNode,
  DeploymentServer,
} from '../../types/';
import { useUser } from '../../hooks/authentication';
import { useProcessNodeParamDetails } from '../../hooks/sapflow';
import { Input, NumericInput, Select } from '../../components/form';
import ListEditor from './ListEditor';
import { SapFlowProcessNode, SapProcessNodeParamDetails } from '../../types/DataDelivery';

const constructValidationShema = (parameters: SapFlowProcessNodeParamDetails[]) => {
  const validationObj: any = {};
  parameters.forEach((param) => {
    if (param.type === SapFlowConfigParamType.String) {
      validationObj[param.name] = yup.string();
    } else if (param.type === SapFlowConfigParamType.Int || param.type === SapFlowConfigParamType.Float) {
      validationObj[param.name] = yup.number();
    } else if (param.type === SapFlowConfigParamType.Boolean) {
      validationObj[param.name] = yup.boolean();
    } else if (param.type === SapFlowConfigParamType.Enum) {
      validationObj[param.name] = yup.string();
    } else if (param.type === SapFlowConfigParamType.Flag) {
      validationObj[param.name] = yup.boolean();
    }
  });
  return yup.object(validationObj);
};

const extractDefaultValues = (parameters: SapFlowProcessNodeParamDetails[] | SapProcessNodeParamDetails[]) => {
  const defaultValues: any = {};
  parameters.forEach((param) => {
    defaultValues[param.name] = param.default_value;
  });
  return defaultValues;
};

interface Props {
  processNode: SapFlowConfigProcessNode | SapFlowProcessNode;
  processNodeIndex: number;
  processNodeParams: SapFlowProcessNodeParamDetails[] | SapProcessNodeParamDetails[];
  sapflowProcessingServer: DeploymentServer;
  loading: boolean;
  form: any;
  show: boolean;
}

const ProcessParamsForm: React.FC<Props> = ({
  processNode,
  form,
  show,
  loading,
  processNodeParams,
  sapflowProcessingServer,
}) => {
  const { getUser } = useUser();
  //const [validationSchema, setValidationSchema] = useState(constructValidationShema(configParams));
  //const paramsQuery = useProcessNodeParamDetails(processNode.toolblock.name, processNode.sap.name);
  const { handleSubmit, control, formState, reset, setValue } = useForm<any>({
    //defaultValues: extractDefaultValues(configParams),
    //resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!loading && processNodeParams && processNodeParams.length > 0) {
      const configParams: any = {};
      processNode.params.forEach((configParam: any) => {
        let key = configParam.key;
        if (key.charAt(0) === '-') {
          key = key.substring(1);
        }
        configParams[key] = configParam.value;
      });
      processNodeParams.forEach((param: SapFlowProcessNodeParamDetails | SapProcessNodeParamDetails, index: number) => {
        const valueKey = processNode.order + '.' + param.name + '.value';
        const activeKey = processNode.order + '.' + param.name + '.active';
        let value = param.default_value;
        //if (form.getValues(valueKey) === null || form.getValues(valueKey) === undefined) {
        let defaultValue = param.default_value;
        if (param.type === SapFlowConfigParamType.ListInt) {
          defaultValue = JSON.parse(param.default_value);
        }
        if (configParams[param.name] !== undefined) {
          defaultValue = configParams[param.name];
          form.setValue(activeKey, true);
        } else {
          form.setValue(activeKey, false);
        }
        if (param.type === SapFlowConfigParamType.Flag || param.type === SapFlowConfigParamType.Boolean) {
          if (typeof defaultValue === 'string') {
            value = defaultValue === 'True' || defaultValue === 'true';
          } else if (typeof defaultValue === 'object') {
            value = defaultValue[0] === 'True' || defaultValue[0] === 'true';
          } else if (defaultValue === null) {
            value = true;
          }
        } else if (param.type === SapFlowConfigParamType.Int) {
          value = parseInt(defaultValue);
        } else if (param.type === SapFlowConfigParamType.Float) {
          value = parseFloat(defaultValue);
        } else if (param.type === SapFlowConfigParamType.ListInt) {
          const listValues = defaultValue;
          value = listValues.map((listValue: any) => {
            return { value: listValue };
          });
        } else if (param.type === SapFlowConfigParamType.Enum) {
          if (typeof defaultValue === 'string') {
            value = defaultValue;
          } else if (defaultValue && defaultValue.length) {
            value = defaultValue[0];
          }
        } else if (param.type === SapFlowConfigParamType.String) {
          if (typeof defaultValue === 'string') {
            value = defaultValue;
          } else if (defaultValue && defaultValue.length) {
            value = defaultValue[0];
          }
          if (value === null || value === undefined) {
            value = '';
          }
        }
        form.setValue(valueKey, value);
        //}
      });
    }
  }, [loading, processNodeParams, processNode]);

  const renderConfigParamController = (
    param: SapFlowProcessNodeParamDetails | SapProcessNodeParamDetails,
    controllerField: any,
    error: FieldError | undefined
  ) => {
    if (param.type === SapFlowConfigParamType.String) {
      return <Input className="w-75" {...controllerField} error={error?.message} type="text" />;
    } else if (param.type === SapFlowConfigParamType.Int || param.type === SapFlowConfigParamType.Float) {
      return <NumericInput className="w-75" {...controllerField} error={error?.message} type="text" />;
    } else if (param.type === SapFlowConfigParamType.Boolean) {
      return (
        <Switch
          {...controllerField}
          error={error?.message}
          type="text"
          onLabel=""
          offLabel=""
          checked={controllerField.value}
        />
      );
    } else if (param.type === SapFlowConfigParamType.Enum) {
      let disabled = false;
      if (param.name === 'processingServer') {
        console.log('Found processingServer param: ' + JSON.stringify(param));
        console.log('sapflowProcessingServer: ' + JSON.stringify(sapflowProcessingServer));
        if (sapflowProcessingServer === null || sapflowProcessingServer.id !== '0') {
          disabled = true;
        }
      }
      return (
        <Select
          className="w-75"
          data={param.possible_values}
          disabled={disabled}
          error={error?.message}
          dataItemKey={controllerField.dataItemKey}
          textField={controllerField.textField}
          value={controllerField.value}
          name={controllerField.name}
          onChange={controllerField.onChange}
        />
      );
    } else if (param.type === SapFlowConfigParamType.ListInt) {
      return (
        <ListEditor
          formInstance={form}
          values={controllerField.value}
          onChange={(newValues) => {
            console.log('Field: ' + JSON.stringify(controllerField));
            form.setValue(controllerField.name, newValues);
          }}
          type={param.type}
          possibleValues={param.possible_values}
        />
      );
    } else return <></>;
  };

  if (!show) return null;

  return (
    <ExpansionPanelContent>
      <Typography.h4 fontWeight="light" className="px-4 pb-2">
        Parameters
      </Typography.h4>
      <>
        <div className="pb-4">
          {loading && (
            <div>
              <Fragment>
                <Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />
              </Fragment>
            </div>
          )}
          {processNodeParams &&
            processNodeParams.length > 0 &&
            processNodeParams.map(
              (param: SapFlowProcessNodeParamDetails | SapProcessNodeParamDetails, index: number) => {
                console.log('Rendering Processing param: ' + JSON.stringify(param));
                return (
                  <div className="d-flex align-items-center justify-content-center p-2" key={index}>
                    <Controller
                      control={form.control}
                      name={processNode.order + '.' + param.name + '.active'}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <Checkbox
                            {...field}
                            label={''}
                            className="d-flex align-items-center justify-content-center flex-fill"
                            checked={field.value}
                            name={field.name}
                          />
                        );
                      }}
                    />
                    <span className="px-2 text-end" style={{ flex: 4 }}>
                      {param.displayname + ' - ' + param.type}
                    </span>
                    <div className="px-2" style={{ flex: 8 }}>
                      <Controller
                        control={form.control}
                        name={processNode.order + '.' + param.name + '.value'}
                        render={({ field, fieldState: { error } }) => renderConfigParamController(param, field, error)}
                      />
                    </div>
                  </div>
                );
              }
            )}
          {!loading && (!processNodeParams || processNodeParams.length === 0) && (
            <div>No parameters found for this process.</div>
          )}
        </div>
      </>
    </ExpansionPanelContent>
  );
};

export default ProcessParamsForm;
