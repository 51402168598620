import React, { ComponentType, useEffect, useRef } from 'react';
import { Window, WindowProps } from '@progress/kendo-react-dialogs';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

interface DialogProps {
  show: boolean;
  className?: string;
  children: any;
  onClose: any;
  title: any;
  style?: any;
  contentPadding?: string;
}

const StyledWindow = styled(Window as ComponentType<WindowProps>)`
  border: none;
  .k-window-content {
    padding: 1rem;
  }
  .k-window-titlebar {
    font-weight: 500;
    color: white;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    border-bottom: 0px;
    .k-window-title {
      font-size: 1.5rem;
      color: var(--geosap-steps-background);
    }
    .k-window-titlebar-actions {
      color: black;
    }
  }
`;

const DialogModal: React.FC<DialogProps> = (props: DialogProps) => {
  const [displayed, setDisplayed] = React.useState(false);
  const [appearing, setAppearing] = React.useState(false);
  const prevShowRef = useRef<boolean>();
  let potentialTimeout: NodeJS.Timeout = null;

  useEffect(() => {
    if (prevShowRef.current !== props.show) {
      if (props.show) {
        setDisplayed(true);
        potentialTimeout = setTimeout(() => {
          setAppearing(true);
          potentialTimeout = null;
        }, 20);
      } else {
        setAppearing(false);
        potentialTimeout = setTimeout(() => {
          setDisplayed(false);
          potentialTimeout = null;
        }, 200);
      }
    }
    prevShowRef.current = props.show;
    return () => {
      if (potentialTimeout) clearTimeout(potentialTimeout);
    };
  }, [props.show, setDisplayed]);
  //console.log('Show: ' + props.show + ', Appearing: ' + appearing);

  const children = (
    <StyledWindow
      className={`animated-opacity ${props.className}`}
      appendTo={null}
      title={props.title}
      onClose={() => {
        props.onClose();
      }}
      style={{
        maxWidth: '80%',
        minWidth: 'unset',
        height: 'unset',
        left: 'unset',
        top: 'unset',
        opacity: props.show && appearing ? 1 : 0,
        ...props.style,
      }}
      draggable={false}
      resizable={false}
      minimizeButton={() => null}
      maximizeButton={() => null}
    >
      {props.children}
    </StyledWindow>
  );

  //const children = props.show ? (
  //  <div
  //    style={{position: "relative", borderRadius: "20px", maxWidth: "600px", padding: 0, color: "#7f7f7f", backgroundColor: "#ffffff", border: "1px solid #dee2e6", }}
  //  >
  //    {props.children}
  //  </div>
  //) : null;

  return ReactDOM.createPortal(
    <div
      className="animated-background"
      style={{
        display: displayed ? 'flex' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: `rgba(0,0,0,${props.show && appearing ? 0.5 : 0})`,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      {children}
    </div>,
    document.body
  );
};

export default DialogModal;
