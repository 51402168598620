import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Organization,
  StripeProduct,
} from '../../../types';
import { useAppContext } from '../../../context/app';
import { centsStrToDollars } from '../../../common/stringHelper';
import { useCheckoutSession } from '../../../hooks/billing';
import { OverlayedLoader } from '../../../components';
import { Loader } from '@progress/kendo-react-indicators';

interface Props {
  index: number;
  product: StripeProduct
}

const SubscriptionPlan: React.FC<Props> = ({index, product}) => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const [hovering, setHovering] = useState<boolean>(false);
  const checkoutSessionMutation = useCheckoutSession();

  useEffect(()=>{
    if(checkoutSessionMutation.isSuccess && checkoutSessionMutation.data) {
        window.location.href = checkoutSessionMutation.data;
    }
  },[checkoutSessionMutation.isSuccess])

  return (
    <div key={index} className="col-12 col-sm-4 d-flex flex-column position-relative">
      <div className="d-flex flex-column p-2 mx-2 text-center cursor-pointer animated-general position-relative" onMouseEnter={()=>{setHovering(true)}} onMouseLeave={()=>{setHovering(false)}} style={{overflow:'hidden', borderRadius: '1rem', boxShadow: hovering?"0 8px 16px rgba(0, 0, 0, 0.2)": "0 4px 8px rgba(0, 0, 0, 0.1)", transform: hovering?"translateY(-5px)":""}} onClick={()=>checkoutSessionMutation.mutateAsync({priceId: product.defaultPrice.id})}>
      <OverlayedLoader show={checkoutSessionMutation.isLoading}/>
        <span className="py-1 fw-bold">{product.name}</span>
        <span className="py-1" style={{fontSize: "0.8rem", color: "var(--geosap-secondary-color)", whiteSpace: "pre-wrap"}}>{product.description}</span>
        <ul>
            {product.marketingFeatures.map((feat, index)=><li key={index}>{feat.name}</li>)}
        </ul>
        <span className="mt-auto" style={{fontWeight: "bold", fontSize: "1rem", color: "var(--geosap-secondary-color)"}}>{centsStrToDollars(product.defaultPrice.unitAmount)}</span>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
