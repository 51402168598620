import { useCallback, useRef } from 'react';

type DelayedExecutionCallback = (func: () => void) => void;

const useDelayedExecution = (delay: number): DelayedExecutionCallback => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const executeAfterDelay: DelayedExecutionCallback = useCallback(
    (func) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        func();
        timerRef.current = null;
      }, delay);
      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      }; // Cleanup function to clear the timer if needed.
    },
    [delay]
  );

  return executeAfterDelay;
};

export default useDelayedExecution;
