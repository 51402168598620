import SapFlowType from '../SapFlowType';
import Category from '../Category';
export interface SapConfigItemNode {
  id: string;
  name: string;
  path: string;
  fileformat: string;
  group: boolean;
  type: 'SUPPLIED' | 'GENERATED';
}

export interface SapConfigProcessNode {
  id: string;
  details: string;
  sapname: string;
  displayname: string;
  order: number;
  sap: {
    id: string;
    name: string;
  };
  toolblock: {
    id: string;
    name: string;
  };
  params: { key: string; value: string }[];
}

export enum SapConfigParamType {
  String = 'String',
  Int = 'Int',
  Float = 'Float',
  Enum = 'Enum',
  Flag = 'Flag',
  Boolean = 'Boolean',
  ListString = 'ListString',
  ListInt = 'ListInt',
  ListFloat = 'ListFloat',
  ListEnum = 'ListEnum',
}

export interface SapConfigParamDetails {
  processNodeId: string;
  processNodeOrder: number;
  strictParams: boolean;
  params: SapProcessNodeParamDetails[];
}

export interface SapProcessNodeParamDetails {
  id: string;
  name: string;
  displayname: string;
  description: string;
  toolbox: string;
  toolblock: string;
  sap: string;
  type: SapConfigParamType;
  default_value: any;
  possible_values: string[];
  version: number;
}

export interface SapConfig {
  id: string;
  name: string;
  description: string;
  pathingRegex: string;
  isCustom: boolean;
  itemnodeconfigs: SapConfigItemNode[];
  processnodeconfigs: SapConfigProcessNode[];
  categories: Category[];
  types: SapFlowType[];
}

export interface SapConfigParamsUpdateDTO {
  processnodeparams: {}[];
}
