import {useMutation, useQuery, useQueryClient} from 'react-query';
import { useAxiosInstance } from '../common';
import { SapConfigParamDetails } from '../../types/DataDelivery';

interface Params {
  sapFlowId: string;
  name: string;
  description: string;
  resetWorkspace: boolean;
}

const useSapFlowUpdate = () => {
  const axios = useAxiosInstance();
  const queryClient = useQueryClient();
  return useMutation(
      async (params: Params) => {
        const response = await axios.put<any>('file_sapflow/' + params.sapFlowId, params);
        return response.data;
      },
      {
        onSuccess: (_data, variables) => {
          queryClient.invalidateQueries(['sapFlow', variables.sapFlowId], { refetchInactive: true });
          queryClient.invalidateQueries(['sapFlowParams', variables.sapFlowId], { refetchInactive: true });
        },
      }
  );
};

export default useSapFlowUpdate;