import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-react-listview';
import { Loader } from '@progress/kendo-react-indicators';
import { Avatar, Menu, MenuItem } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';
import { SvgIcon } from '@progress/kendo-react-common';
import { Error } from '@progress/kendo-react-labels';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { useRefreshUser, useSelectOrganization, useUser } from '../../../hooks/authentication';
import {
  useInviteOrganizationMember,
  useOrganizationDelete,
  useOrganizationMemberRemove,
  useOrganizationMembers,
  useOrganizationSharingUpdate,
  useOrganizationUpdate,
  useOrgInvitations,
  useUpdateUserOrgRole,
} from '../../../hooks/user_management';
import {
  AuthorityLevel,
  AuthorityType,
  Organization,
  OrganizationInvitation,
  OrganizationMember,
  User,
  UserRole,
} from '../../../types';
import { HumanReadableRole, toEnumRole, toReadableRole } from '../../../converters/userRoleConverter';
import AddUserDialog from './AddUserDialog';
import { RestrictedSubmitButton } from '../../../components/restricted';
import Dialog from '../../../components/Dialog';
import { SubmitButton, ToggleableButton } from '../../../components/form';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useAppContext } from '../../../context/app';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { Field, FieldRenderProps, Form, FormElement } from '@progress/kendo-react-form';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { REQUIRED_FIELD } from '../../../common/constants';

interface Props {
  organization: Organization;
}

const MembersSection: React.FC<Props> = (props) => {
  const { dispatch } = useAppContext();
  const navigate = useNavigate();
  const { getUser, getCurrentOrganization, userHasAuthority, userHasRole } = useUser();
  const [invitingUser, setInvitingUser] = useState<boolean>(false);
  const [removingUser, setRemovingUser] = useState<OrganizationMember>(null);
  const [changingUserRole, setChangingUserRole] = useState<OrganizationMember>(null);
  const [confirmingRoleAssignment, setConfirmingRoleAssignment] = useState<UserRole>(null);
  const [newRoleOffset, setNewRoleOffset] = useState<{ left: number; top: number }>({ left: 0, top: 0 });
  const updateUserRoleMutation = useUpdateUserOrgRole();
  const organizationMembersQuery = useOrganizationMembers(props.organization.id);
  const orgInvitationsQuery = useOrgInvitations(props.organization.id);
  const inviteMemberMutation = useInviteOrganizationMember();
  const removeMemberMutation = useOrganizationMemberRemove();
  const handleAddUser = () => {
    setInvitingUser(true);
  };
  const handleRemoveUser = (member: OrganizationMember) => {
    setRemovingUser(member);
  };
  const handleConfirmedRemoveUser = () => {
    removeMemberMutation.mutateAsync({ organizationId: removingUser.organization.id, userId: removingUser.user.id });
    setRemovingUser(null);
  };

  const handleResendInvitation = (email: string) => {
    inviteMemberMutation.mutateAsync({ organizationId: props.organization.id, email, resend: true }).then(() => {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: { notification: { content: `Invitation resent to ${email}!`, type: 'success' } },
      });
    });
  };

  const renderMembersHeader = (data: {}[], type: string) => {
    return (
      <ListViewHeader
        style={{
          color: 'rgb(160, 160, 160)',
          fontSize: 14,
          marginBottom: '2rem',
        }}
        className="pl-3 pb-2 pt-2 d-flex justify-content-between"
      >
        <Typography.h5>{type}</Typography.h5>
        <RestrictedSubmitButton
          themeColor="primary"
          onClick={handleAddUser}
          size="small"
          label={'Invite new user'}
          authorityType={AuthorityType.ORG_AUTHORITY}
          authorityLevel={AuthorityLevel.UPDATE}
          full={false}
        ></RestrictedSubmitButton>
      </ListViewHeader>
    );
  };
  const renderMembersFooter = (data: {}[], type: string) => {
    const members = data?.length ? data?.length : 0;
    return (
      <ListViewFooter
        style={{
          color: 'rgb(160, 160, 160)',
          fontSize: 14,
        }}
        className="pl-3 pb-2 pt-2"
      >
        {`${members} ${type}${members > 1 ? 's' : ''}`}
      </ListViewFooter>
    );
  };

  const renderMemberItem = (props: any) => {
    const item: OrganizationMember = props.dataItem;
    const role: string = toReadableRole(item.role?.role);
    return (
      <div
        className="k-listview-item row p-2 border-bottom align-middle"
        style={{
          margin: 0,
        }}
      >
        <div className="col-2">
          <Avatar type="img">
            <img
              style={{ width: '100%', height: '100%' }}
              src={`https://ui-avatars.com/api/?name=${item.user.firstName}+${item.user.lastName}`}
              alt="KendoReact ListView Contacts Avatar"
            />
          </Avatar>
        </div>
        <div className="col-6">
          <h2
            style={{
              fontSize: 14,
              color: '#454545',
              marginBottom: 0,
            }}
            className="text-uppercase"
          >
            {item.user?.firstName + ' ' + item.user?.lastName}
          </h2>
          <div
            style={{
              fontSize: 12,
              color: '#a0a0a0',
            }}
          >
            {item.user.email}
          </div>
        </div>
        <div className="col-4" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div
            className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base mx-1"
            onClick={(e) => {
              if (userHasAuthority(AuthorityType.ORG_AUTHORITY, AuthorityLevel.UPDATE)) {
                setChangingUserRole(item);
                setNewRoleOffset({ left: e.clientX, top: e.clientY });
              }
              e.stopPropagation();
            }}
          >
            <div className="k-chip-content">{role}</div>
            <SvgIcon icon={caretAltDownIcon} style={{ fontSize: '1.2rem' }} />
          </div>

          <RestrictedSubmitButton
            themeColor="error"
            onClick={() => {
              handleRemoveUser(item);
            }}
            size="small"
            fillMode={'outline'}
            label={'Remove'}
            authorityType={AuthorityType.ORG_AUTHORITY}
            authorityLevel={AuthorityLevel.UPDATE}
            full={false}
            className="mx-1"
          ></RestrictedSubmitButton>
        </div>
      </div>
    );
  };

  const renderInvitationItem = (props: any) => {
    const item: OrganizationInvitation = props.dataItem;
    return (
      <div
        className="k-listview-item row p-2 border-bottom align-middle"
        style={{
          margin: 0,
        }}
      >
        <div className="col-2"></div>
        <div className="col-6">
          <h2
            style={{
              fontSize: 14,
              color: '#454545',
              marginBottom: 0,
            }}
            className="text-uppercase"
          >
            {item.userEmail}
          </h2>
        </div>
        <div className="col-4">
          <RestrictedSubmitButton
            themeColor="primary"
            onClick={() => {
              handleResendInvitation(item.userEmail);
            }}
            size="small"
            label={'Resend invitation'}
            authorityType={AuthorityType.ORG_AUTHORITY}
            authorityLevel={AuthorityLevel.UPDATE}
            full={false}
            loading={inviteMemberMutation.isLoading && inviteMemberMutation.variables.email === item.userEmail}
          ></RestrictedSubmitButton>
        </div>
      </div>
    );
  };

  const handlePageClick = () => {
    if (changingUserRole) {
      setChangingUserRole(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handlePageClick);
    return () => {
      document.removeEventListener('click', handlePageClick);
    };
  }, [changingUserRole]);

  useEffect(() => {
    if (!updateUserRoleMutation.isLoading && updateUserRoleMutation.isSuccess) {
      setConfirmingRoleAssignment(null);
      setChangingUserRole(null);
    }
  }, [updateUserRoleMutation.isLoading, updateUserRoleMutation.isSuccess]);

  const renderRoleMenu = () => {
    return (
      <Popup show={changingUserRole !== null} offset={newRoleOffset} popupClass={'popup-content'}>
        <Menu
          vertical={true}
          style={{ display: 'inline-block' }}
          onSelect={({ item, itemId, nativeEvent, syntheticEvent, target }) => {
            syntheticEvent.stopPropagation();
            setConfirmingRoleAssignment(toEnumRole(item.text as HumanReadableRole));
          }}
        >
          {Object.values(UserRole).map((role, index) => {
            if (role !== UserRole.ROLE_SYSTEMADMIN)
              return (
                <MenuItem
                  key={index}
                  text={toReadableRole(role)}
                  disabled={false}
                  render={() => <span>{toReadableRole(role)}</span>}
                />
              );
            return null;
          })}
        </Menu>
      </Popup>
    );
  };

  const renderRoleConfirmation = () => {
    return (
      <Dialog
        show={confirmingRoleAssignment ? true : false}
        title={'Share '}
        onClose={() => {
          setConfirmingRoleAssignment(null);
          setChangingUserRole(null);
        }}
        style={{ width: '600px' }}
      >
        <p
          style={{
            margin: '25px',
            textAlign: 'center',
          }}
        >
          Are you sure you want to assign the role of
          <span style={{ fontStyle: 'italic' }}>{' ' + toReadableRole(confirmingRoleAssignment)}</span>
          &nbsp;to <span style={{ fontWeight: 'bold' }}>{changingUserRole?.user?.firstName}</span>
        </p>
        <DialogActionsBar>
          <SubmitButton
            label="Cancel"
            themeColor="primary"
            onClick={() => {
              setConfirmingRoleAssignment(null);
              setChangingUserRole(null);
            }}
            loading={updateUserRoleMutation.isLoading}
          />
          <SubmitButton
            label="OK"
            themeColor="primary"
            onClick={() => {
              updateUserRoleMutation.mutateAsync({
                user_id: changingUserRole.user.id,
                org_id: changingUserRole.organization.id,
                new_role: confirmingRoleAssignment,
              });
            }}
            loading={updateUserRoleMutation.isLoading}
          />
        </DialogActionsBar>
      </Dialog>
    );
  };

  const renderMembers = () => {
    return (
      <div>
        {orgInvitationsQuery.isLoading || (organizationMembersQuery.isLoading && <Loader></Loader>)}
        {orgInvitationsQuery.isSuccess &&
          !organizationMembersQuery.isLoading &&
          orgInvitationsQuery.data &&
          orgInvitationsQuery.data.length > 0 && (
            <ListView
              data={orgInvitationsQuery.data}
              item={renderInvitationItem}
              style={{
                width: '100%',
              }}
              header={() => renderMembersHeader(orgInvitationsQuery.data, 'Invitations')}
              footer={() => renderMembersFooter(orgInvitationsQuery.data, 'invitation')}
            />
          )}
        {organizationMembersQuery.isSuccess && !orgInvitationsQuery.isLoading && (
          <ListView
            data={organizationMembersQuery.data}
            item={renderMemberItem}
            style={{
              width: '100%',
            }}
            header={() => renderMembersHeader(organizationMembersQuery.data, 'Members')}
            footer={() => renderMembersFooter(organizationMembersQuery.data, 'member')}
          />
        )}
      </div>
    );
  };

  return (
    <div className="py-4">
      <AddUserDialog
        organization={props.organization}
        show={invitingUser}
        onClose={() => {
          setInvitingUser(false);
        }}
      ></AddUserDialog>
      <ConfirmationDialog
        show={removingUser ? true : false}
        onConfirm={handleConfirmedRemoveUser}
        onClose={() => {
          setRemovingUser(null);
        }}
        title={'Remove User from Org'}
        text={
          removingUser
            ? `Do you want to to remove user ` +
              removingUser.user.firstName +
              ' ' +
              removingUser.user.lastName +
              ' from organization ' +
              removingUser.organization.name +
              '?'
            : ''
        }
        loading={false}
      ></ConfirmationDialog>
      {renderRoleConfirmation()}
      <div className="d-flex flex-column col-12 col-md-8 mx-auto">
        {renderRoleMenu()}
        {renderMembers()}
      </div>
    </div>
  );
};

export default MembersSection;
