export interface DeploymentServer {
    id: string;
    machineName: string;
    inetAddress: string;
    online: boolean;
    geosapServerEnabled?: boolean;
    geosapServerOnline?: boolean;
    geosapServerSuffix?: string;
    rootsServerEnabled?: boolean;
    rootsServerOnline?: boolean;
    rootsServerSuffix?: string;
    imageServerEnabled?: boolean;
    imageServerOnline?: boolean;
    imageServerSuffix?: string;
    communicatorServerEnabled?: boolean;
    communicatorServerOnline?: boolean;
    communicatorServerSuffix?: string;
    foliageServerEnabled?: boolean;
    foliageServerOnline?: boolean;
    foliageServerSuffix?: string;
    dataProcServerEnabled?: boolean;
    dataProcServerOnline?: boolean;
    dataProcServerSuffix?: string;
}

export interface ServerTask {
    id: string,
    //server: DeploymentServer,
    server_id: string,
    server_type: EServerType,
    parent: {id: string, server: DeploymentServer},
    statusMessage: string,
    type: ETaskType,
    resource: {resourceType: string, resourceId: string},
    currentStatus: ETaskStatus,
    statusHistory: ServerTaskStatus[]
}

export interface ServerTaskStatus {
    id: string,
    task: {id: string, server_id: string, server_type: EServerType},
    status: ETaskStatus,
    message: string,
    statusTime: string
}

export enum ETaskType {
    PROCESSING = "PROCESSING",
    ZIPPING = "ZIPPING"
}

export enum ETaskStatus {
    CREATED = "CREATED",
    STARTED = "STARTED",
    IN_QUEUE = "IN_QUEUE",
    IN_PROCESS = "IN_PROCESS",
    COMPLETED = "COMPLETED",
    ERRORED = "ERRORED"
}

export enum EServerType {
    GS_SERVER = "GS_SERVER",
    GS_FOLIAGE_SERVER = "GS_FOLIAGE_SERVER",
    GS_ROOTS_SERVER = "GS_ROOTS_SERVER",
    GS_COMMUNICATOR_SERVER = "GS_COMMUNICATOR_SERVER",
    GS_IMAGE_SERVER = "GS_IMAGE_SERVER",
    GS_DATA_PROCESSING_SERVER = "GS_DATA_PROCESSING_SERVER"
}

