import React, { useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SvgIcon } from '@progress/kendo-react-common';
import { xCircleIcon } from '@progress/kendo-svg-icons';
import { FileNode } from '../../../types/DataDelivery';
import Dropzone from '../../Dropzone';
import { SubmitButton } from '../../form';
import { FileNodeUploadWizardStep, useConsumeFileNodeUploadWizardState } from '../../../context/fileNodeUploadWizard';

const SelectFiles: React.FC = () => {
  const { dispatch, files } = useConsumeFileNodeUploadWizardState();

  const handleDrop = (acceptedFiles: File[]) => {
    dispatch({ type: 'SELECT_FILES', payload: { files: acceptedFiles } });
  };

  const removeFile = (fileToRemove: File) => {
    dispatch({ type: 'SELECT_FILES', payload: { files: files.filter((file) => file !== fileToRemove) } });
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
      <Dropzone onDrop={handleDrop}></Dropzone>
      {files.length !== 0 && (
        <div style={{ marginTop: '1rem' }}>
          <h2 style={{ fontSize: '1.25rem', fontWeight: 'semibold', marginBottom: '0.5rem' }}>Uploaded Files</h2>
          <ul style={{ listStyleType: 'disc', paddingLeft: '1.25rem' }}>
            {files.map((file, index) => (
              <li key={index} style={{ color: '#4b5563', display: 'flex', justifyContent: 'space-between' }}>
                {file.name}
                <SvgIcon
                  icon={xCircleIcon}
                  onClick={() => removeFile(file)}
                  size="medium"
                  style={{ fontSize: '1.2rem', position: 'relative', cursor: 'pointer' }}
                ></SvgIcon>
              </li>
            ))}
          </ul>
        </div>
      )}
      <SubmitButton
        label="Select Destination"
        onClick={() => {
          dispatch({ type: 'CHANGE_STEP', payload: { step: FileNodeUploadWizardStep.SELECT_DESTINATION } });
        }}
        disabled={files.length === 0}
        style={{ justifySelf: 'flex-end' }}
        xMargin="mx-0"
      ></SubmitButton>
    </div>
  );
};

export default SelectFiles;
