import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { SvgIcon } from '@progress/kendo-react-common';
import { moreHorizontalIcon, folderIcon, uploadIcon } from '@progress/kendo-svg-icons';
import moment from 'moment';
import { useAppContext } from '../../context/app';
import {
  useRootStructure,
  useGenerateFileNodes,
  useProcessFileNode,
  useCleanFileNode,
  useDeleteFileNode,
} from '../../hooks/data_delivery';
import { useFileNodeActions } from '../../actions/';
import { RestrictedSubmitButton } from '../../components/restricted';
import ContextMenu from '../../components/filenodes/ContextMenu';
import { StyledGrid, StyledTextButton } from '../../components/styled';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { useTable } from '../../hooks/common';
import { DOWNLOAD_SIZE_WARNING, FileNode, FileNodeStatus } from '../../types/DataDelivery';
import { SubmitButton } from '../../components/form';
import { AuthorityLevel, AuthorityType, TusUpload, UserRole } from '../../types';
import { useUser } from '../../hooks/authentication';
import DownloadWarningModal from '../../components/filenodes/DownloadWarningModal';
import { formatSizeUnits } from '../../converters/formatSizeUnits';
import PropertiesPanel from '../../components/filenodes/PropertiesPanel';
import CreateRootFileNodeModal from '../../components/filenodes/CreateFolderFileNodeModal';
import MoveFileNodeModal from '../../components/filenodes/MoveFileNodeModal';
import FileNodeUploadModal from '../../components/filenodes/FileUploader/FileNodeUploadModal';
import { useUploadContext } from '../../context/uploadContext';

const DataDelivery: React.FC = () => {
  const navigate = useNavigate();
  const [filenodes, setFilenodes] = useState<FileNode[]>([]);
  const { dispatch, screenWidth } = useAppContext();
  const { dispatch: uploadDispatch } = useUploadContext();
  const [creatingRootFileNode, setCreatingRootFileNode] = useState(false);
  const [movingFileNode, setMovingFileNode] = useState(null);
  const [deletingFileNode, setDeletingFileNode] = useState<FileNode>(null);
  const [confirmingDownloadFileNode, setConfirmingDownloadFileNode] = useState<FileNode>(null);
  const { getUser, getCurrentOrganization, userHasAuthority } = useUser();
  const {
    handleCreateFolderFileNode,
    handlePrepareFileNode,
    handleProcessFileNode,
    handleUpdateFileNode,
    handleUpdateFileNodeGeolocation,
    handleCleanFileNode,
    handleDownloadFileNode,
    handleDeleteFileNode,
    handleForgetFileNode,
  } = useFileNodeActions(getCurrentOrganization().id);
  const rootStructureQuery = useRootStructure(getCurrentOrganization().id);
  const [contextMenuFileNode, setContextMenuFileNode] = React.useState(null);
  const [contextOffset, setContextOffset] = React.useState(null);
  const [showProperties, setShowProperties] = useState(false);
  const [fileNodeProperties, setFileNodeProperties] = useState<FileNode>(null);
  const deleteFileNodeMutation = useDeleteFileNode(getCurrentOrganization().id);
  const [uploadingFileNode, setUploadingFileNode] = useState(false);
  const tableProps = useTable({
    pageSize: -1,
    data: filenodes,
    sortDescriptor: { field: 'created', dir: 'desc' },
  });

  useEffect(() => {
    if (!userHasAuthority(AuthorityType.DATA_AUTHORITY, AuthorityLevel.UPDATE)) {
      navigate('/deliver/root');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', () => {
      if (contextMenuFileNode) {
        setContextMenuFileNode(null);
      }
    });
  }, [contextMenuFileNode]);

  useEffect(() => {
    if (rootStructureQuery.isSuccess && !rootStructureQuery.isFetching && rootStructureQuery.data) {
      const rootFilenode: FileNode = rootStructureQuery.data;
      if (rootFilenode.children && rootFilenode.children.length > 0) {
        setFilenodes(rootFilenode.children);
      }
    }
  }, [rootStructureQuery.isSuccess, rootStructureQuery.isFetching]);

  if (!rootStructureQuery.isSuccess) return null;

  const ActionsCellWithErrorHandler: React.FC<{ dataItem: FileNode }> = ({ dataItem }) => (
    <ActionsCell
      dataItem={dataItem}
      onPrepareClick={() => {
        handlePrepareFileNode(dataItem);
      }}
      onOpenMenu={(fileNode, e) => {
        console.log('A');
        e.stopPropagation();
        setContextOffset({ left: e.clientX, top: e.clientY });
        setContextMenuFileNode(fileNode);
        return true;
      }}
    />
  );

  const StatusCellWithErrorHandler: React.FC<{ dataItem: FileNode }> = ({ dataItem }) => (
    <StatusCell dataItem={dataItem} />
  );

  const createRootFileNode = async (fileNodeName: string) => {
    await handleCreateFolderFileNode(fileNodeName, null);
  };

  const deleteFileNode = async (id: string) => {
    await deleteFileNodeMutation.mutateAsync({ fileNodeId: id, rootId: null });
    setDeletingFileNode(null);
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex' }}>
      <DownloadWarningModal
        filenode={confirmingDownloadFileNode}
        show={confirmingDownloadFileNode !== null}
        handleClose={() => {
          setConfirmingDownloadFileNode(null);
        }}
        onConfirm={() => {
          handleDownloadFileNode(confirmingDownloadFileNode);
          setConfirmingDownloadFileNode(null);
        }}
      />

      <ContextMenu
        show={contextMenuFileNode !== null}
        fileNode={contextMenuFileNode}
        offset={contextOffset}
        onDownloadFileNode={() => {
          if (contextMenuFileNode.size && contextMenuFileNode.size > DOWNLOAD_SIZE_WARNING) {
            setConfirmingDownloadFileNode(contextMenuFileNode);
            setContextMenuFileNode(null);
          } else {
            handleDownloadFileNode(contextMenuFileNode);
          }
        }}
        onProcessFileNode={handleProcessFileNode}
        onUpdateFileNode={handleUpdateFileNode}
        onUpdateFileNodeGeolocation={handleUpdateFileNodeGeolocation}
        onCleanFileNode={handleCleanFileNode}
        onForgetFileNode={handleForgetFileNode}
        onMoveFileNode={handleForgetFileNode}
        onDeleteFileNode={handleDeleteFileNode}
        onPropertiesFileNode={(filenode) => {
          setContextMenuFileNode(null);
          setShowProperties(true);
          setFileNodeProperties(filenode);
        }}
      />

      {uploadingFileNode && (
        <FileNodeUploadModal
          show={uploadingFileNode}
          handleDone={(uploadToFocus: TusUpload) => {
            setUploadingFileNode(false);
            uploadDispatch({
              type: 'UPLOADS_VISIBILITY',
              payload: { visibility: true, selectedUpload: uploadToFocus },
            });
          }}
          handleClose={() => {
            setUploadingFileNode(false);
          }}
          rootFileNode={rootStructureQuery.data}
        />
      )}

      <PropertiesPanel
        show={showProperties}
        onClose={() => {
          setShowProperties(false);
        }}
        rootFileNode={fileNodeProperties}
        fileNode={fileNodeProperties}
      />
      <CreateRootFileNodeModal
        show={creatingRootFileNode}
        handleClose={() => {
          setCreatingRootFileNode(false);
        }}
        defaultValue="New Project"
        onConfirm={(name: string) => {
          setCreatingRootFileNode(false);
          createRootFileNode(name);
        }}
      />
      <MoveFileNodeModal
        show={movingFileNode !== null}
        handleClose={() => {
          setMovingFileNode(null);
        }}
        fileNode={movingFileNode}
      />
      {rootStructureQuery.isFetching && (
        <div
          className=""
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            background: 'var(--geosap-steps-background)',
            zIndex: 100,
          }}
        >
          <Loader size="small" type={'converging-spinner'} themeColor={'primary'} />
        </div>
      )}
      {deletingFileNode && (
        <ConfirmationDialog
          show={true}
          onConfirm={() => {
            deleteFileNode(deletingFileNode.id);
          }}
          onClose={() => {
            setDeletingFileNode(null);
          }}
          loading={deleteFileNodeMutation.isLoading}
          title="Please confirm"
          text="Are you sure you wish to delete this Transaction ?"
        />
      )}

      <div style={{ padding: '2rem', display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '1.5rem',
            boxShadow: '0 0 10px #fff',
            WebkitBoxShadow: '0 0 10px rgb(0,0,0,0.20)',
            background: 'white',
            overflow: 'hidden',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '2rem' }}>
            <Typography.h2 style={{}}>Data Delivery Processing Manager</Typography.h2>
            <div style={{ display: 'flex' }}>
              <SubmitButton
                label="Upload Data"
                full={false}
                uppercase={false}
                onClick={() => {
                  setUploadingFileNode(true);
                }}
                fillMode="outline"
                themeColor={null}
                className="k-theme-color-outline-warning-yellow mx-2 d-none d-lg-inline-flex"
                icon={uploadIcon}
                iconSize="xlarge"
                defaultFont={true}
                style={{ fontWeight: 600, fontSize: '1.5rem' }}
              />
              <SubmitButton
                label="New Project"
                full={false}
                uppercase={false}
                onClick={() => {
                  setCreatingRootFileNode(true);
                }}
                className="d-none d-lg-inline-flex"
                fillMode="outline"
                themeColor="tertiary"
                icon={folderIcon}
                defaultFont={true}
                style={{ fontWeight: 600, fontSize: '1.5rem' }}
              />
            </div>
          </div>

          <StyledGrid
            style={{ flex: '1' }}
            {...tableProps}
            pageable={false}
            sortable
            total={filenodes.length}
            //detail={DetailsLogViewer}
            expandField="expanded"
            onRowClick={({ dataItem }) => {
              //if (dataItem.msgHeader.msgType !== EMsgType.HEARTBEAT) handleExpand(dataItem);
            }}
            rowRender={(trElement, props) => {
              let trProps: any = null;
              trProps = {
                style: { cursor: 'pointer' },
              };
              return React.cloneElement(
                trElement,
                {
                  ...trProps,
                },
                trElement.props.children as any
              );
            }}
          >
            {screenWidth > 992 && <GridColumn cell={DateCell} title="Created date" headerClassName="text-center" />}
            <GridColumn cell={PathCell} title="Project Name" />
            {screenWidth > 992 && <GridColumn cell={SizeCell} width={200} title="Size" headerClassName="text-center" />}

            {/*<GridColumn cell={StatusCellWithErrorHandler} title="Status" />*/}
            <GridColumn cell={ActionsCellWithErrorHandler} title="Actions" headerClassName="text-center"></GridColumn>
          </StyledGrid>
        </div>
      </div>
    </div>
  );
};

//const DetailsLogViewer: React.FC<GridDetailRowProps> = (props: GridDetailRowProps) => {
//  const divRef = useRef<HTMLDivElement>();
//
//  useEffect(() => {
//    if (divRef.current?.parentElement?.tagName === 'TD') {
//      const tdEl = divRef.current.parentElement as HTMLTableCellElement;
//      tdEl.colSpan = tdEl.colSpan + 1;
//      tdEl.ariaColIndex = '1';
//    }
//  }, []);
//
//  return (
//    <div ref={divRef}>
//      <div className="px-4 pb-1">
//        {props.dataItem && props.dataItem.payload && typeof props.dataItem.payload === 'object' && (
//          <ul>
//            {Object.keys(props.dataItem.payload) /*.slice(0, 5)*/
//              .map((payloadKey: string) => (
//                <li key={`${payloadKey}`} className="py-1">
//                  <span className="ps-1">{payloadKey}</span>
//                  <span className="ps-1">:</span>
//                  <span className="ps-1">{props.dataItem.payload[payloadKey]}</span>
//                </li>
//              ))}
//          </ul>
//        )}
//      </div>
//    </div>
//  );
//};

const StyledErrorTextButton = styled(StyledTextButton)`
  color: var(--geosap-error);
  &:hover {
    color: var(--geosap-error-link-hover);
  }
`;

interface ActionsCellProps {
  dataItem: FileNode;
  onPrepareClick: (fileNode: FileNode) => void;
  onOpenMenu: (fileNode: FileNode, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const ActionsCell: React.FC<ActionsCellProps> = ({ dataItem, onPrepareClick, onOpenMenu }) => {
  const navigate = useNavigate();
  const { userHasRole } = useUser();
  const viewButton = () => (
    <SubmitButton
      label="View"
      size="small"
      themeColor={null}
      className="px-4 k-theme-color-view-green"
      full={false}
      onClick={() => {
        navigate('/deliver/' + dataItem.id);
      }}
      defaultFont={true}
      style={{ fontWeight: 600, fontSize: '1rem' }}
    ></SubmitButton>
  );
  const prepareButton = () => (
    <RestrictedSubmitButton
      label="Prepare"
      themeColor={null}
      className="px-4 k-theme-color-warning-yellow"
      full={false}
      size="small"
      onClick={() => onPrepareClick(dataItem)}
      authorityType={AuthorityType.DATA_AUTHORITY}
      authorityLevel={AuthorityLevel.EXECUTE}
      defaultFont={true}
      style={{ fontWeight: 600, fontSize: '1rem' }}
    ></RestrictedSubmitButton>
  );

  if (dataItem.id === '00000000-0000-0000-0000-000000000000') {
    return (
      <td>
        <div className="d-flex gap-3 justify-content-center">{prepareButton()}</div>
      </td>
    );
  } else {
    return (
      <td>
        <div className="d-flex gap-3 justify-content-center">
          {dataItem.status === FileNodeStatus.CREATED && prepareButton()}
          {dataItem.status === FileNodeStatus.PROCESSING && (
            <Loader className="px-1" size="small" type={'converging-spinner'} themeColor={'primary'} />
          )}
          {dataItem.status === FileNodeStatus.READY && viewButton()}

          {userHasRole(UserRole.ROLE_SYSTEMADMIN) && (
            <div
              className="px-1"
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={(e) => {
                onOpenMenu(dataItem, e);
              }}
            >
              <SvgIcon icon={moreHorizontalIcon} size="large" />
            </div>
          )}
        </div>
      </td>
    );
  }
};

interface StatusCellProps {
  dataItem: FileNode;
}

const StatusCell: React.FC<StatusCellProps> = ({ dataItem }) => {
  const navigate = useNavigate();

  let label = 'UPLOADED';
  let color: 'warning' | 'error' | 'success' = 'warning';
  if (dataItem.id === '00000000-0000-0000-0000-000000000000') {
    label = 'INVALID';
    color = 'warning';
  } else if (dataItem.status === FileNodeStatus.CREATED) {
    label = 'REQUIRES PROCESSING';
    color = 'warning';
  } else if (dataItem.status === FileNodeStatus.PROCESSING) {
    label = 'PROCESSING...';
    color = 'warning';
  } else if (dataItem.status === FileNodeStatus.READY) {
    label = 'READY';
    color = 'success';
  } else if (dataItem.status === FileNodeStatus.ERRORED_IN_PROCESSING) {
    label = 'ERRORED';
    color = 'error';
  } else {
    return null;
  }

  return (
    <td>
      <Typography.h5 className="d-flex gap-3 mb-0" themeColor={color}>
        {label}
      </Typography.h5>
    </td>
  );
};

const SizeCell: React.FC<StatusCellProps> = ({ dataItem }) => {
  if (dataItem.size) {
    return (
      <td>
        <Typography.p className="gap-3 mb-0 text-center">{formatSizeUnits(dataItem.size)}</Typography.p>
      </td>
    );
  } else {
    return <td></td>;
  }
};

const PathCell: React.FC<StatusCellProps> = ({ dataItem }) => {
  if (dataItem.name) {
    return (
      <td style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Typography.h5 className="d-block gap-3 mb-0">{dataItem.name}</Typography.h5>
        <Typography.h6
          className={`d-block gap-3 mb-0 ${dataItem.status === FileNodeStatus.PROCESSING ? '' : 'display-none'}`}
          style={{ color: 'var(--status-processing)' }}
        >
          {dataItem.status}
        </Typography.h6>
      </td>
    );
  } else {
    return <td></td>;
  }
};

const DateCell: React.FC<StatusCellProps> = ({ dataItem }) => {
  if (dataItem.createdDate) {
    return (
      <td>
        <Typography.p className="gap-3 mb-0 text-center">
          {moment(dataItem.createdDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')}
        </Typography.p>
      </td>
    );
  } else {
    return <td></td>;
  }
};

export default DataDelivery;
