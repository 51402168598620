import React, { Fragment, useEffect, useState } from 'react';
//import { useNavigate, useLocation } from 'react-router-dom';
import { Typography } from '@progress/kendo-react-common';
import { Reveal } from '@progress/kendo-react-animation';
import { Skeleton } from '@progress/kendo-react-indicators';
import { ExpansionPanelActionEvent, ExpansionPanelContent } from '@progress/kendo-react-layout';
import StyledExpansionPanel from '../../components/styled/StyledExpansionPanel';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { truncateText } from '../../common/stringHelper';
import 'jsoneditor-react/es/editor.min.css';
import { useConsumeSapFlowWizardState } from '../../context/sapFlowWizard';
import {
  SapFlowProject,
  SapFlow,
  SapFlowProcessNode,
  SapFlowConfigProcessNode,
  SapFlowConfigParamDetails,
  SapFlowProcessNodeParamDetails,
  SapFlowConfigParamType,
} from '../../types';
import {
  useProjects,
  useCreateTransaction,
  useCreateProject,
  useSapFlowParamsUpdate,
  useConfigParams,
} from '../../hooks/sapflow';
import { useUser } from '../../hooks/authentication';
import { Input, SubmitButton, Select } from '../../components/form';
import { StyledTextButton } from '../../components/styled';
import TransitionAnimation from '../../components/TransitionAnimation';
import ProcessParamsForm from './ProcessParamsForm';

declare const window: any;

interface FormValues {
  project: SapFlowProject | null;
  projectName: string;
  processName: string;
  processDescription: string;
  projectTags: string[] | null;
  processNodeSelected: SapFlowConfigProcessNode | null;
}

const getValidationSchema = () =>
  yup.object({
    project: undefined,
    projectName: undefined,
    processName: undefined, //yup.object().required(REQUIRED_FIELD),
    processDescription: undefined, //yup.object().required(REQUIRED_FIELD),
    processNodeSelected: undefined,
  });

const ProvideDetailsStep = () => {
  const { getUser } = useUser();
  const { dispatch, sapFlowConfig } = useConsumeSapFlowWizardState();
  const [newProject, setNewProject] = useState(false);
  const sapFlowParamsUpdateMutation = useSapFlowParamsUpdate(); //sapFlow.id);
  const transactionMutation = useCreateTransaction();
  const paramsQuery = useConfigParams(sapFlowConfig.id);
  const projectQuery = useProjects();
  const projectMutation = useCreateProject();
  const [validationSchema, setValidationSchema] = useState(getValidationSchema());
  const { handleSubmit, control, formState, reset, watch, setValue } = useForm<FormValues>({
    defaultValues: {
      project: null,
      projectName: '',
      processDescription: '',
      projectTags: null,
      processNodeSelected: null,
    },
    resolver: yupResolver(validationSchema),
  });
  const paramsForm = useForm<any>();
  const sapFlowConfigValue = watch('processNodeSelected');
  const projectValue = watch('project');
  const projectNameValue = watch('projectName');

  const handleExpansionPanelAction = (selected: SapFlowConfigProcessNode | null, event: ExpansionPanelActionEvent) => {
    setValue('processNodeSelected', event.expanded ? null : selected, { shouldDirty: true });
  };

  const provideDetails = async ({ processName, processDescription, project, projectName }: FormValues) => {
    const userId = getUser().id;
    const newProject = await projectMutation.mutateAsync({
      project_id: project ? project.id : '',
      project_name: projectName,
    });
    window.dataLayer.push({
      event: 'Creating SAPflow',
      step: '2',
      stepName: 'Project Created or Selected',
      projectName: projectName,
      projectId: newProject.id,
    });
    const sapFlow: SapFlow = await transactionMutation.mutateAsync({
      configid: sapFlowConfig?.id,
      userid: getUser().id,
      projectid: newProject.id,
      name: processName,
      description: processDescription,
    });
    dispatch({
      type: 'CREATE_SAPFLOW',
      payload: {
        sapFlow: sapFlow,
        processName,
        processDescription,
        project: newProject,
      },
    });
    window.dataLayer.push({
      event: 'Creating SAPflow',
      step: '3',
      stepName: 'SAPFlow Created',
      projectName: projectName,
      processDescription: processDescription,
      sapFlowConfigId: sapFlowConfig.id,
      sapFlowId: sapFlow.id,
    });
    if (paramsQuery.isSuccess && paramsQuery.data.length > 0) {
      // Proceed to update sapflow parameters
      const paramValues = paramsForm.getValues();
      const processnodeparams: any = {};
      sapFlow.processnodes.forEach((processNode: SapFlowProcessNode) => {
        // filter flag params
        const processNodeParams = paramValues[processNode.order];
        const originalValues = paramsQuery.data.filter((param) => param.processNodeOrder === processNode.order)[0]
          .params;
        Object.keys(processNodeParams).filter((param: any) => {
          if (!processNodeParams[param].active) {
            delete processNodeParams[param];
          }
        });
        processnodeparams[processNode.id] = {};
        Object.keys(processNodeParams).forEach((processNodeParam) => {
          const originalParam = originalValues.filter(
            (originalParamDetails) => originalParamDetails['name'] === processNodeParam
          )[0];
          if (originalParam && originalParam.type === SapFlowConfigParamType.ListInt) {
            processnodeparams[processNode.id][processNodeParam] = processNodeParams[processNodeParam].value.map(
              (listValue: any) => listValue.value
            );
          } else {
            processnodeparams[processNode.id][processNodeParam] = processNodeParams[processNodeParam].value;
          }
        });
      });
      const paramUpdateBody = {
        sapflowid: sapFlow.id,
        processnodeparams,
      };
      const updateResponse = await sapFlowParamsUpdateMutation.mutateAsync(paramUpdateBody);
    }
    dispatch({
      type: 'PROVIDE_DETAILS',
      payload: {},
    });
    return;
  };

  const parametersAvailable = true;
  return (
    <TransitionAnimation>
      <div style={{ paddingTop: '4rem' }}>
        <Typography.h1 fontWeight="light" textAlign="center">
          {sapFlowConfig.name}
        </Typography.h1>
        <div>
          <form onSubmit={handleSubmit(provideDetails)}>
            <div className="w-75 mx-auto">
              <Typography.h4 fontWeight="light" textAlign="center">
                Associated project
              </Typography.h4>
              <Controller
                control={control}
                name="projectName"
                render={({ field, fieldState: { error } }) =>
                  newProject && (
                    <Input {...field} error={error?.message} type="text" placeholder="Project Name" autoFocus />
                  )
                }
              />
              <Controller
                control={control}
                name="project"
                render={({ field, fieldState: { error } }) =>
                  !newProject && (
                    <Select
                      name={field.name}
                      value={field.value}
                      error={error?.message}
                      data={projectQuery.data}
                      dataItemKey="id"
                      textField="name"
                      placeholder="Existing projects"
                      loading={projectQuery.isLoading}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      autoFocus
                    />
                  )
                }
              />
              <div className="pt-1 pb-4">
                <StyledTextButton type="button" onClick={() => setNewProject((prevState) => !prevState)}>
                  {newProject ? 'Or select existing project' : 'Or create new project'}
                </StyledTextButton>
              </div>

              <Typography.h4 fontWeight="light" textAlign="center">
                Details
              </Typography.h4>

              <Controller
                control={control}
                name="processName"
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} error={error?.message} type="text" placeholder="Process Name" autoFocus />
                )}
              />
              <Controller
                control={control}
                name="processDescription"
                render={({ field, fieldState: { error } }) => (
                  <Input {...field} error={error?.message} type="text" placeholder="Process Description" />
                )}
              />
            </div>
            <Typography.h4 className="mt-4" fontWeight="light" textAlign="center">
              Parameters
            </Typography.h4>
            {parametersAvailable && (
              <div className="m-auto mt-4 w-75">
                {sapFlowConfig === null && (
                  <div>
                    {new Array(5)
                      .fill(<Skeleton shape="rectangle" style={{ width: '100%', height: '74px' }} className="my-3" />)
                      .map((skeleton, i) => (
                        <Fragment key={i}>{skeleton}</Fragment>
                      ))}
                  </div>
                )}
                {sapFlowConfig !== null &&
                  sapFlowConfig.processnodeconfigs.length > 0 &&
                  sapFlowConfig.processnodeconfigs.map((processNode: SapFlowConfigProcessNode, index: number) => {
                    let processNodeParams: SapFlowProcessNodeParamDetails[] = [];
                    if (paramsQuery.isSuccess && paramsQuery.data.length > 0) {
                      const processNodeParamDetails: SapFlowConfigParamDetails = paramsQuery.data.filter(
                        (param) => param.processNodeId === processNode.id
                      )[0];
                      if (processNodeParamDetails) {
                        processNodeParams = processNodeParamDetails.params;
                      }
                    }
                    return (
                      <StyledExpansionPanel
                        key={processNode.id}
                        title={
                          <>
                            <Typography.h3 className="flex-grow-1 text-capitalize">
                              {truncateText(index + 1 + ' - ' + processNode.displayname, 46)}
                            </Typography.h3>
                          </>
                        }
                        expanded={sapFlowConfigValue?.id === processNode.id}
                        tabIndex={0}
                        onAction={handleExpansionPanelAction.bind(undefined, processNode)}
                        expandIcon="k-icon k-i-arrow-chevron-right animated-transform rotated-0"
                        collapseIcon="k-icon k-i-arrow-chevron-right animated-transform rotated--90"
                      >
                        <Reveal>
                          <ProcessParamsForm
                            processNodeIndex={index}
                            processNode={processNode}
                            loading={paramsQuery.isLoading}
                            processNodeParams={processNodeParams}
                            sapflowProcessingServer={null}
                            form={paramsForm}
                            show={sapFlowConfigValue?.id === processNode.id}
                          />
                        </Reveal>
                      </StyledExpansionPanel>
                    );
                  })}
              </div>
            )}
            <div className="py-3 w-75 d-flex justify-content-end mx-auto">
              <SubmitButton
                label="Submit details"
                uppercase={false}
                full={false}
                disabled={
                  !formState.isDirty ||
                  projectQuery.isLoading ||
                  (newProject && projectNameValue === '') ||
                  (!newProject && projectValue === null)
                }
                //disabled={!formState.isDirty /* && !formHasValue*/}
                loading={
                  projectMutation.isLoading || transactionMutation.isLoading || sapFlowParamsUpdateMutation.isLoading
                }
              />
            </div>
          </form>
        </div>
      </div>
    </TransitionAnimation>
  );
};

export default ProvideDetailsStep;
