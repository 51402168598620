import React, { useState, useRef, useEffect } from 'react';
import { Popup, Offset } from '@progress/kendo-react-popup';
import { Menu, MenuItem, MenuItemModel } from '@progress/kendo-react-layout';
import { AuthorityLevel, AuthorityType, LayerType, UserRole, ViewLayer } from '../../types';
import { useUser } from '../../hooks/authentication';
import { FileNode, FileNodeType, FileNodeStatus, FileNodeGeolocation } from '../../types/DataDelivery';

interface ContextMenuProps {
  show: boolean;
  fileNode: FileNode;
  offset: Offset;
  onViewFileNode?(fileNode: any): void;
  onDownloadFileNode(fileNode: any): void;
  onProcessFileNode(fileNode: any): void;
  onZoomToFileNode?(fileNode: any): void;
  onUpdateFileNode(fileNode: any): void;
  onUpdateFileNodeGeolocation(fileNode: any): void;
  onCleanFileNode(fileNode: any): void;
  onForgetFileNode(fileNode: any): void;
  onMoveFileNode(fileNode: any): void;
  onDeleteFileNode(fileNode: any): void;
  onPropertiesFileNode(fileNode: any): void;
}

const ContextMenu: React.FC<ContextMenuProps> = (props: ContextMenuProps) => {
  const { userHasAuthority, userHasRole } = useUser();
  const availableOptions: MenuItemModel[] = [];
  const userCanUpdate: boolean = userHasAuthority(AuthorityType.DATA_AUTHORITY, AuthorityLevel.UPDATE);
  const userCanDelete: boolean = userHasAuthority(AuthorityType.DATA_AUTHORITY, AuthorityLevel.DELETE);
  if (props.onViewFileNode) {
    availableOptions.push({
      text: 'View',
      data: {
        action: () => {
          props.onViewFileNode(props.fileNode);
        },
      },
      disabled: false,
    });
  }
  if (props.onZoomToFileNode) {
    availableOptions.push({
      text: 'Zoom to',
      data: {
        action: () => {
          props.onZoomToFileNode(props.fileNode);
        },
      },
      disabled: props.fileNode?.geolocation ? false : true,
    });
  }
  availableOptions.push({
    text: 'Download',
    data: {
      action: () => {
        props.onDownloadFileNode(props.fileNode);
      },
    },
    disabled: false,
  });
  if (userCanUpdate) {
    availableOptions.push({
      text: 'Process',
      data: {
        action: () => {
          props.onProcessFileNode(props.fileNode);
        },
      },
      disabled: props.fileNode?.status === FileNodeStatus.PROCESSING,
    });
  }
  if (userCanUpdate) {
    availableOptions.push({
      text: 'Update',
      data: {
        action: () => {
          props.onUpdateFileNode(props.fileNode);
        },
      },
      disabled: props.fileNode?.status === FileNodeStatus.PROCESSING,
    });
  }
  if (userCanUpdate && props.fileNode?.isDir) {
    availableOptions.push({
      text: 'Update Geolocation',
      data: {
        action: () => {
          props.onUpdateFileNodeGeolocation(props.fileNode);
        },
      },
      disabled: props.fileNode?.status === FileNodeStatus.PROCESSING,
    });
  }
  if (userCanUpdate) {
    availableOptions.push({
      text: 'Move',
      data: {
        action: () => {
          props.onMoveFileNode(props.fileNode);
        },
      },
      disabled: props.fileNode?.status === FileNodeStatus.PROCESSING,
    });
  }
  if (userCanUpdate) {
    availableOptions.push({
      text: 'Clean',
      data: {
        action: () => {
          props.onCleanFileNode(props.fileNode);
        },
      },
      disabled: !userHasRole(UserRole.ROLE_SYSTEMADMIN) && props.fileNode?.status === FileNodeStatus.PROCESSING,
    });
  }
  if (userCanUpdate) {
    availableOptions.push({
      text: 'Forget',
      data: {
        action: () => {
          props.onForgetFileNode(props.fileNode);
        },
      },
      disabled: props.fileNode?.status === FileNodeStatus.PROCESSING,
    });
  }
  if (userCanDelete) {
    availableOptions.push({
      text: 'Delete',
      data: {
        action: () => {
          props.onDeleteFileNode(props.fileNode);
        },
      },
      disabled: false,
    });
  }
  availableOptions.push({
    text: 'Properties',
    data: {
      action: () => {
        props.onPropertiesFileNode(props.fileNode);
      },
    },
  });
  return (
    <Popup show={props.offset !== null && props.fileNode !== null} offset={props.offset} popupClass={'popup-content'}>
      <Menu
        vertical={true}
        style={{ display: 'inline-block' }}
        items={availableOptions}
        onSelect={({ item, itemId, nativeEvent, syntheticEvent, target }) => {
          item.data.action();
          syntheticEvent.stopPropagation();
        }}
      ></Menu>
    </Popup>
  );
};

export default ContextMenu;
