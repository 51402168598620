import React, { useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { SvgIcon } from '@progress/kendo-react-common';
import { xCircleIcon } from '@progress/kendo-svg-icons';
import { SubmitButton } from '../../form';
import { FileNodeUploadWizardStep, useConsumeFileNodeUploadWizardState } from '../../../context/fileNodeUploadWizard';
import FileNodeBrowser from '../FileNodeBrowser';
import { useTusUploader } from '../../../hooks/common';
import { FileNode } from '../../../types/DataDelivery';
import { useAppContext } from '../../../context/app';
import { TusUpload } from '../../../types';

interface Props {
  onDone: (upload: TusUpload) => void;
}
const SelectDestination: React.FC<Props> = (props: Props) => {
  const { startUpload, pauseUpload, cancelUpload } = useTusUploader();
  const { dispatch, rootFileNode, files } = useConsumeFileNodeUploadWizardState();
  const [currentFileNode, setCurrentFileNode] = useState<FileNode>(rootFileNode);

  const startUploads = () => {
    const uploads = files.map((file) => {
      return startUpload(file, currentFileNode);
    });
    props.onDone(uploads.length === 1 ? uploads[0] : null);
    //dispatch({ type: 'DONE' });
  };

  const removeFile = (fileToRemove: File) => {
    dispatch({ type: 'SELECT_FILES', payload: { files: files.filter((file) => file !== fileToRemove) } });
  };

  return (
    <div className="h-100 d-flex flex-column">
      <div style={{ marginTop: '1rem' }}>
        <h2 style={{ fontSize: '1.25rem', fontWeight: 'semibold', marginBottom: '0.5rem' }}>Uploaded Files</h2>
        <ul style={{ listStyleType: 'disc', paddingLeft: '1.25rem' }}>
          {files.map((file, index) => (
            <li key={index} style={{ color: '#4b5563', display: 'flex', justifyContent: 'space-between' }}>
              {file.name}
              <SvgIcon
                icon={xCircleIcon}
                onClick={() => removeFile(file)}
                size="medium"
                style={{ fontSize: '1.2rem', position: 'relative', cursor: 'pointer' }}
              ></SvgIcon>
            </li>
          ))}
        </ul>
      </div>
      <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', flex: '1', overflow: 'hidden' }}>
        <Typography.h4>Select upload location</Typography.h4>
        <FileNodeBrowser
          rootFileNode={rootFileNode}
          selectedFileNode={currentFileNode}
          onSelect={(node) => {
            setCurrentFileNode(node);
          }}
        ></FileNodeBrowser>
      </div>

      <SubmitButton
        label="Upload"
        onClick={startUploads}
        disabled={files.length === 0}
        style={{
          justifySelf: 'flex-end',
          marginTop: '1rem',
        }}
        xMargin="mx-0"
      ></SubmitButton>
    </div>
  );
};

export default SelectDestination;
