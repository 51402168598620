import { EServerType } from '../System';

export interface SapFlow {
  id: string;
  sapflowname: string;
  sapflowdescription: string;
  status: SapFlowItemStatus;
  createdDate: string;
  lastModifiedDate: string;
  configid: string;
  processnodes: SapFlowProcessNode[];
  //itemnodes: SapFlowConfigItemNode[];
  transactionHistory: SapFlowStatus[];
  transactionPercentComplete: number;
  transactionMessage: string;
  transactionStartTime: number;
  transactionFinishTime: number;
  transactionRuntime: number;
  resetWorkspace: boolean;
  resetOutput: boolean;
  copyToWorkspace: boolean;
}

export enum SapFlowItemStatus {
  TRANSACTION_CREATED = 'TRANSACTION_CREATED',
  TRANSACTION_READY = 'TRANSACTION_READY',
  TRANSACTION_STARTED = 'TRANSACTION_STARTED',
  TRANSACTION_IN_PROCESS = 'TRANSACTION_IN_PROCESS',
  TRANSACTION_INTERVENTION_REQUIRED = 'TRANSACTION_INTERVENTION_REQUIRED',
  TRANSACTION_FINISHED = 'TRANSACTION_FINISHED',
  TRANSACTION_ERRORED = 'TRANSACTION_ERRORED',
  TRANSACTION_TIMED_OUT = 'TRANSACTION_TIMED_OUT',
}

export enum SapFlowProcessStatus {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  ERRORED = 'ERRORED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface SapFlowItem {
  id: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  status: SapFlowItemStatus;
  fileformat: string;
  group: boolean;
  type: string;
}

export interface SapGroupStatus {
  id: string;
  groupID: string;
  status: SapFlowItemStatus;
  statusMessage: string;
  statusTime: string;
}

export interface SapFlowStatus {
  id: string;
  currentTransactionCPUUsage: number;
  currentTransactionMemoryUsage: number;
  currentTransactionPercentComplete: number;
  currentTransactionRuntime: number;
  statusMessage: string;
  statusTime: string;
  transactionID: string;
  transactionStatus: SapFlowItemStatus;
  selected?: boolean;
}

export interface SapFlowProcessNode {
  displayname: string;
  id: string;
  configid: string;
  inputitems: any; //{Input: "674b8fc1-67fd-4b57-967d-537930f13f5d"}
  order: number;
  outputitems: any; //{Output: "674b8fc1-67fd-4b57-967d-537930f13f5d"}
  params: any; // [{key: "view", value: ["false"]}]
  percentComplete: number;
  processFinishTime: number;
  processNodeStatusHistory: SapFlowProcessNodeStatus[];
  processRuntime: number;
  processStartTime: string;
  processMessage: string;
  name: string;
  sapname: string;
  applicationType: EServerType;
  status: SapFlowProcessStatus;
  sync: boolean;
  timeout: number;
  toolblockdescription: string;
  toolblockname: string;
}

export interface SapFlowProcessNodeStatus {
  id: string;
  transactionID: string;
  processNodeID: string;
  currentProcessStatus: SapFlowProcessStatus;
  statusMessage: string;
  statusTime: string;
  TotalProcessNodeCount: number;
  currentProcessNodeCount: number;
  currentProcessRuntime: number;
  currentProcessPercentComplete: number;
  currentProcessNodeIndex: number;
}

export interface RequiredFileStatus {
  ProvisionType: 'Upload' | 'Reuse';
  Uploaded: boolean;
  FilesToReuse: any;
}

export enum SapFlowGroupType {
  ROOT = 'ROOT',
  SAPCONFIG = 'SAPCONFIG',
  GROUP = 'GROUP',
}

export interface SapFlowGroup {
  id: string;
  name: string;
  description: string;
  groupType: SapFlowGroupType;
  configid: string;
  configname: string;
  parent: SapFlowGroup;
  sapflows: SapFlow[];
  groups: SapFlowGroup[];
  status: SapFlowItemStatus;
}
