import {
  User,
  AppNotification,
  ZipProgressNotification,
  Organization,
  SharedFileNode,
  SharedTransaction,
  UploadProgressNotification,
  TusUpload,
} from '../../types';

export interface AppState {
  user: User | null;
  accessToken: string | null;
  viewAccessToken: string | null;
  sharedFilenode: SharedFileNode | null;
  sharedTransaction: SharedTransaction | null;
  tokenType: string | null;
  selectedOrganization: Organization | null;
  availableOrganizations: Organization[] | null;
  notification: AppNotification | null;
  zippingNotifications: ZipProgressNotification[];
  uploadNotifications: UploadProgressNotification[];
  recentlyCreatedUser: string | null;
  screenWidth: number;
  screenHeight: number;
}

export const initialState: AppState = {
  user: null,
  accessToken: null,
  viewAccessToken: null,
  sharedFilenode: null,
  sharedTransaction: null,
  tokenType: null,
  selectedOrganization: null,
  availableOrganizations: [],
  notification: null,
  zippingNotifications: [],
  uploadNotifications: [],
  recentlyCreatedUser: null,
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight,
};
