import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useNotification } from '../../hooks/common';
import { useWebsocketClient } from '../../hooks/messaging';
import { useUser } from '../../hooks/authentication';
import {
  Login,
  OrganizationSelection,
  ConfirmEmail,
  ConfirmationPending,
  ForgotPassword,
  PasswordReset,
  GeosapViewerWrapper,
  DataDeliveryViewer,
  SharedCenter,
  NoOrganizationAvailable,
} from '../../pages';
import RegisterWizard from '../../pages/RegisterWizard/RegisterWizard';
import { AppNotification, ZipProgressNotifications, UploadProgressNotifications } from '../../components/feedback';
import UploadTracker from '../../components/filenodes/UploadTracker/UploadTracker';
import Page from './Page';
import PrivateRoutes from './PrivateRoutes';
import '../../style/app.scss';
import { UserRole } from '../../types';
import { useAppContext } from '../../context/app';
import TagManager from 'react-gtm-module';

const App: React.FC = React.memo(() => {
  const location = useLocation();
  const { dispatch } = useAppContext();
  const { clearOnRouteChange } = useNotification();
  const { isAuthenticated, isViewAuthenticated, organizationSelected, getCurrentOrganization, userHasRole } = useUser();
  const { initWebsocketClient, closeConnection, isSubscribedTo, subscribe, connected } = useWebsocketClient();

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
      auth: process.env.REACT_APP_GTM_AUTH,
      preview: process.env.REACT_APP_GTM_PREVIEW,
      dataLayer: { organization: getCurrentOrganization()?.name },
    };

    TagManager.initialize(tagManagerArgs);
  }, [getCurrentOrganization()]);

  useEffect(() => clearOnRouteChange(), [location]);

  useEffect(() => {
    if ((isAuthenticated() || isViewAuthenticated()) && !connected) {
      initWebsocketClient(); // Initialize the Websocket connection.
    } else {
      closeConnection();
    }
  }, [isAuthenticated(), isViewAuthenticated()]);

  useEffect(() => {
    //console.log('Conneted : ' + connected);
    // If Websocket connected
    if (connected) {
      // And an Organization has been selected
      if (organizationSelected()) {
        // And we aren't already subscribed to the organization topic
        if (!isSubscribedTo('/topic/ORGANIZATION/' + getCurrentOrganization().id)) {
          subscribe('/topic/ORGANIZATION/' + getCurrentOrganization().id);
        }
      }

      if (!isSubscribedTo('/topic/FILENODE/00000000-0000-0000-0000-000000000000')) {
        subscribe('/topic/FILENODE/00000000-0000-0000-0000-000000000000');
      }

      if (userHasRole(UserRole.ROLE_SYSTEMADMIN)) {
        subscribe('/topic/SYSTEM');
      }
    }
  }, [connected, organizationSelected()]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);

  function handleResize(this: Window, ev: UIEvent) {
    dispatch({ type: 'SCREEN_RESIZE', payload: { screenWidth: this.innerWidth, screenHeight: this.innerHeight } });
  }

  return (
    <div className="App">
      <Routes>
        <Route
          key="login"
          path="/login"
          element={
            <Page title="Signin">
              <Login />
            </Page>
          }
        />
        <Route
          key="register"
          path="/register"
          element={
            <Page title="Signup">
              <RegisterWizard />
            </Page>
          }
        />
        <Route
          path="/selectorganization"
          element={
            <Page title="Organization Selection">
              <OrganizationSelection />
            </Page>
          }
        />
        <Route
          path="/noorganization"
          element={
            <Page title="No Organization Available">
              <NoOrganizationAvailable />
            </Page>
          }
        />
        <Route
          path="/confirmemail"
          element={
            <Page title="Email Confirmation">
              <ConfirmEmail />
            </Page>
          }
        />
        <Route
          path="/pending"
          element={
            <Page title="Confirmation Pending">
              <ConfirmationPending />
            </Page>
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <Page title="Forgot Password">
              <ForgotPassword />
            </Page>
          }
        />
        <Route
          path="/resetpassword"
          element={
            <Page title="Reset Password">
              <PasswordReset />
            </Page>
          }
        />
        <Route
          path="/shared/:shareId"
          element={
            <Page title="Shared Data Delivery">
              <SharedCenter />
            </Page>
          }
        />
        <Route
          path="/shared-deliver/:rootId"
          element={
            <Page title="Shared Data Delivery">
              <DataDeliveryViewer />
            </Page>
          }
        />
        <Route
          path="/shared-deliver/:rootId/:fileNodeId"
          element={
            <Page title="Viewer">
              <GeosapViewerWrapper />
            </Page>
          }
        />
        <Route
          path="/shared-viewer/:transactionId"
          element={
            <Page title="Shared Data Delivery">
              <GeosapViewerWrapper />
            </Page>
          }
        />
        <Route key="private" path="*" element={<PrivateRoutes />} />
      </Routes>
      <AppNotification />
      <ZipProgressNotifications />
      <UploadProgressNotifications />
      <UploadTracker />
    </div>
  );
});
App.displayName = 'App';

export default App;
