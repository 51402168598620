import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppProvider } from './context/app';
import { UploadContextProvider } from './context/uploadContext';
import App, { ErrorBoundary } from './pages/App';
import TagManager from 'react-gtm-module';
import { LOCAL_STORAGE_DEVICE_ID, LOCAL_STORAGE_USER } from './common/constants';
import { uuidv4 } from './common/uuid';

declare const window: any;

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW,
};

TagManager.initialize(tagManagerArgs);

// App-Cues setup
if (window.Appcues !== null && window.Appcues !== undefined) {
  let uuid = localStorage.getItem(LOCAL_STORAGE_DEVICE_ID);
  const userInfos: { email: string } = { email: null };
  if (!uuid) {
    const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER));
    if (user) {
      uuid = user.id;
      userInfos.email = user.email;
    } else {
      uuid = uuidv4();
    }
    localStorage.setItem(LOCAL_STORAGE_DEVICE_ID, uuid);
  }
  window.Appcues.identify(uuid, userInfos);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <AppProvider>
          <UploadContextProvider>
            <App />
          </UploadContextProvider>
        </AppProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  </BrowserRouter>
);
